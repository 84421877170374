import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import YetkiYonetimiBox from '../components/YetkiYonetimi'

function YetkiYonetimi() {
  return (
    <div>
      <Header />
      <NavBar />
      <YetkiYonetimiBox/>
    </div>
  )
}

export default YetkiYonetimi

