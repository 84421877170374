import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import HesapTakiveLogs from '../components/HesapTakviyeLogs'

function HesapKasatakviyeleri() {
  return (
    <div>
    <Header/>
    <NavBar/>
    <HesapTakiveLogs/>
      
    </div>
  )
}

export default HesapKasatakviyeleri
