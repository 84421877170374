import React, { useEffect, useState } from 'react';
import { HiBars3BottomLeft } from "react-icons/hi2";
import { FaCheck } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import '../App.css';
import InvestmentForm from './YatırımForm';
import InvestmentTable from './YatırımTable';
import DateInput from './DateInput';
import axios from 'axios';
const BASE_URL = process.env.REACT_APP_API_URL;
const YatırımContent = () => {
    const [showFilterMenu, setShowFilterMenu] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [refs, setRefs] = useState([]); // Store all refs
    const [selectedRefs, setSelectedRefs] = useState([]); // Store selected ref IDs
    const [accountSearchTerm, setAccountSearchTerm] = useState('');
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [refsSearchTerm, setRefsSearchTerm] = useState('');
    const [filteredRefs, setFilteredRefs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [minAmount, setMinAmount] = useState('');
    const [maxAmount, setMaxAmount] = useState('');
    const [filterConfig, setFilterConfig] = useState({
        accountNumbers: [],
        refIds: [], // Add refIds to filter configuration
        minAmount: '',
        maxAmount: '',
        startDate: '',
        endDate: '',
        autoUpdate: true
    });
    useEffect(() => {
        fetchBankAccounts();
        fetchRefsAccounts();
    }, []);
    useEffect(() => {
        if (accountSearchTerm.trim() === '') {
            setFilteredAccounts(bankAccounts);
            return;
        }
        const searchTerms = accountSearchTerm.toLowerCase().split(' ');
        const filtered = bankAccounts.filter(account => {
            const accountString = `${account.accountName} ${account.bankName} ${account.accountNumber}`.toLowerCase();
            return searchTerms.every(term => accountString.includes(term));
        });
        setFilteredAccounts(filtered);
    }, [accountSearchTerm, bankAccounts]);
    useEffect(() => {
        if (refsSearchTerm.trim() === '') {
            setFilteredRefs(refs);
            return;
        }
        const searchTerms = refsSearchTerm.toLowerCase().split(' ');
        const filtered = refs.filter(ref => {
            const refString = `${ref.refName} ${ref.refId}`.toLowerCase();
            return searchTerms.every(term => refString.includes(term));
        });
        setFilteredRefs(filtered);
    }, [refsSearchTerm, refs]);
    const fetchBankAccounts = async () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/bank/accounts`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const investmentAccounts = response.data.data.filter(account => account);
            setBankAccounts(investmentAccounts);
            setFilteredAccounts(investmentAccounts);
        } catch (err) {
            setError('Banka hesapları yüklenirken bir hata oluştu');
            console.error('Error fetching bank accounts:', err);
        } finally {
            setLoading(false);
        }
    };
    const fetchRefsAccounts = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/refs/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const sortedRefs = response.data.data.sort((a, b) =>
                a.refName.localeCompare(b.refName)
            );
            setRefs(sortedRefs);
            setFilteredRefs(sortedRefs);
            setError(null);
        } catch (error) {
            console.error("Error fetching refs:", error);
            setError("Failed to load refs. Please try again later.");
        } finally {
            setLoading(false);
        }
    };


    const handleAccountChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
        const selectedAccountsInfo = selectedOptions.map(accountId => {
            const account = bankAccounts.find(acc => acc.accountId === accountId);
            return account ? account.accountNumber : null;
        }).filter(Boolean);
        setSelectedAccounts(selectedOptions);
        setFilterConfig(prev => ({
            ...prev,
            accountNumbers: selectedAccountsInfo
        }));
    };


    const handleRefsChange = (e) => {
        // Seçilen refId'leri al
        const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);

        // Seçilen refId'lere göre, hesapları filtrele
        const selectedAccountsInfo = bankAccounts.filter(account => {
            // Eğer account'ların refId'si seçilen refId'lerle eşleşiyorsa
            return selectedOptions.some(refId => account.refId === refId);
        }).map(account => account.accountNumber);

        // refIds'yi güncelle
        setSelectedRefs(selectedOptions);

        // FilterConfig'i güncelle
        setFilterConfig(prev => ({
            ...prev,
            refIds: selectedOptions,
            accountNumbers: selectedAccountsInfo
        }));
    };



    const handleMinAmountChange = (e) => {
        const value = e.target.value;
        setMinAmount(value);
        setFilterConfig(prev => ({
            ...prev,
            minAmount: value
        }));
    };
    const handleMaxAmountChange = (e) => {
        const value = e.target.value;
        setMaxAmount(value);
        setFilterConfig(prev => ({
            ...prev,
            maxAmount: value
        }));
    };
    const handleDateChange = ({ startDate, endDate }) => {
        setFilterConfig(prev => ({
            ...prev,
            startDate,
            endDate
        }));
    };
    const toggleAutoUpdate = () => {
        setFilterConfig(prev => ({
            ...prev,
            autoUpdate: !prev.autoUpdate
        }));
    };
    const toggleFilterMenu = () => {
        setShowFilterMenu(prev => !prev);
    };
    return (
        <div className="w-full border-4 bg-slate-200 border-slate-200">
            <div className="flex flex-col p-5 border-4 gap-4 items-start justify-center">
                {/* Header Section */}
                <div className="flex items-center justify-between border border-black border-r-0 border-t-0 border-l-0 pb-2 flex-wrap gap-4 w-full">
                    <button
                        onClick={() => setShowModal(true)}
                        type="button"
                        className="p-1.5 text-xs rounded-lg bg-emerald-500 text-white hover:bg-emerald-600 transition-colors"
                    >
                        Banka Yatırım Talebi Oluştur
                    </button>
                    <div className="flex flex-row items-center text-sm gap-3 justify-center">
                        <DateInput onDateChange={handleDateChange} />
                    </div>
                </div>
                {/* Investment Form Modal */}
                {showModal && (
                    <InvestmentForm
                        show={showModal}
                        title="Yatırım Oluşturma Formu"
                        onClose={() => setShowModal(false)}
                    />
                )}
                {/* Filter Toggle Button */}
                <div
                    onClick={toggleFilterMenu}
                    className="items-center cursor-pointer gap-1 justify-center flex-row text-xs flex hover:text-emerald-600"
                >
                    <button type="button">Filtrele</button>
                    <HiBars3BottomLeft />
                </div>
                {/* Filter Section */}
                {!showFilterMenu && (
                    <div className="w-full flex items-start  flex-wrap gap-6 justify-between">
                        <div className="flex items-center flex-wrap flex-1 gap-6 justify-start ">
                            {/* Bank Accounts Section */}
                            <div className="flex flex-col items-start text-sm justify-center gap-3.5">
                                <h5 className="font-medium">Banka Hesapları</h5>
                                <div className="flex flex-col w-full min-w-[300px] gap-2">
                                    {/* Account Search */}
                                    <div className='flex flex-row items-start '>
                                        <div className='flex flex-col items-start gap-2'>
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    placeholder="Hesap ara..."
                                                    value={accountSearchTerm}
                                                    onChange={(e) => setAccountSearchTerm(e.target.value)}
                                                    className="w-full pl-8 pr-4 py-1.5 rounded-md text-xs border border-gray-300 
                                                     focus:outline-none focus:ring-2 focus:ring-emerald-500
                                                     placeholder-gray-400"
                                                />
                                                <IoSearch className="absolute left-2.5 top-1/2 transform -translate-y-1/2 text-gray-400" size={14} />
                                            </div>
                                            {/* Accounts Multi-Select */}
                                            <select
                                                multiple
                                                value={selectedAccounts}
                                                onChange={handleAccountChange}
                                                className="w-full rounded-md text-xs border border-gray-300 
                                                 focus:outline-none focus:ring-2 focus:ring-emerald-500
                                                 disabled:bg-gray-100 disabled:cursor-not-allowed"
                                                disabled={loading}
                                                size={3}
                                            >
                                                {filteredAccounts.map(account => (
                                                    <option
                                                        key={account.accountId}
                                                        value={account.accountId}
                                                        className="p-2 px-4 border-b text-xs border-gray-100 last:border-b-0
                                                         hover:bg-emerald-50 cursor-pointer"
                                                    >
                                                        {account.accountName} - {account.bankName} - (TR {account.accountNumber})
                                                    </option>
                                                ))}
                                            </select>
                                          
                                                <h2 className='font-thin text-xs text-start pl-2'>Cmd veya Ctrl'ye basarak çoklu seçim yapabilirsiniz.</h2>
                                  
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Refs Section */}
                            <div className="flex flex-col items-start text-sm mb-6 justify-center gap-3">
                                <h5 className="font-medium">Siteler</h5>

                                {/* Refs Search */}
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Site ara..."
                                        value={refsSearchTerm}
                                        onChange={(e) => setRefsSearchTerm(e.target.value)}
                                        className="w-full pl-8 pr-4 py-1.5 rounded-md text-xs border border-gray-300 
                                                     focus:outline-none focus:ring-2 focus:ring-emerald-500
                                                     placeholder-gray-400"
                                    />
                                    <IoSearch className="absolute left-2.5 top-1/2 transform -translate-y-1/2 text-gray-400" size={14} />
                                </div>
                                {/* Refs Multi-Select */}
                                <select
                                    multiple
                                    value={selectedRefs}
                                    onChange={handleRefsChange}
                                    className="w-full rounded-md text-xs border border-gray-300 
                                                 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                    disabled={loading}
                                    size={3}
                                >
                                    {filteredRefs.map(ref => (
                                        <option
                                            key={ref.refId}
                                            value={ref.refId}
                                            className="p-2 px-4 border-b text-xs border-gray-100 last:border-b-0
                                                         hover:bg-emerald-50 cursor-pointer"
                                        >
                                            {ref.refName}
                                        </option>
                                    ))}
                                </select>

                            </div>


                            <div>
                            </div>
                        </div>

                        {/* Amount Range and Auto-Update Section */}
                        <div className="flex flex-col items-start justify-center gap-5">

                            <div className="flex flex-row items-center text-xs justify-center gap-1">
                                <h1>Tutar Aralığı</h1>
                                <input
                                    type="number"
                                    placeholder="0 ₺"
                                    value={minAmount}
                                    onChange={handleMinAmountChange}
                                    className="rounded-sm text-center w-28 p-1 text-xs border border-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                />
                                <span>ve</span>
                                <input
                                    type="number"
                                    placeholder="500.000 ₺"
                                    value={maxAmount}
                                    onChange={handleMaxAmountChange}
                                    className="rounded-sm w-28 text-center p-1 text-xs border border-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                />
                            </div>
                            <div className="flex items-center justify-center">
                                <div
                                    onClick={toggleAutoUpdate}
                                    className={`text-xs p-1 flex items-center justify-center text-white  w-full rounded-lg transition-colors ${filterConfig.autoUpdate ? 'bg-emerald-600' : 'bg-red-600'
                                        }`}
                                >
                                    <FaCheck size={10} />
                                    <button
                                        type="button"
                                        className={`text-xs p-1  w-full rounded-lg transition-colors ${filterConfig.autoUpdate ? 'bg-emerald-600' : 'bg-red-600'
                                            }`}
                                    >
                                        {filterConfig.autoUpdate
                                            ? 'Otomatik Güncelleştirme Açık'
                                            : 'Otomatik Güncelleştirme Kapalı'}
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                )}

            </div>

            {/* Investment Table */}
            <InvestmentTable
                filters={filterConfig}
                autoUpdate={filterConfig.autoUpdate}
            />
        </div>
    );
};
export default YatırımContent;