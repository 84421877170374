import React, { useState, useEffect } from 'react';
import axios from 'axios';
import KasaEkleBox from './KasaEkleBox';
import KasaCıkarBox from './KasaCıkarBox';
import ConfirmBox from './ConfirmBox';
import BankaUpdateForm from './BankaUpdateForm';
import Logs from '../components/Logs';

const BASE_URL = process.env.REACT_APP_API_URL;

const YatırımYapılabilirHesapForm = () => {
    const [showKasaEkle, setShowKasaEkle] = useState(false);
    const [showKasaCıkar, setShowKasaCıkar] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [selectedAccountBalance, setSelectedAccountBalance] = useState(null);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [confirmModal, setConfirmModal] = useState(null);
    const [updateModal, setUpdateModal] = useState(null);
    const [logsModal, setLogsModal] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/bank/accounts`);
            const sortedUsers = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setUsers(sortedUsers);
            setError(null);
        } catch (error) {
            console.error("Error fetching bank accounts:", error);
            setError("Failed to load bank accounts. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleKasaTakviyeOn = (accountId, accountBalance) => {
        setSelectedAccountId(accountId);
        setSelectedAccountBalance(accountBalance);
        setShowKasaEkle(true);
    };

    const handleKasaLogs = (accountId) => {
        setSelectedAccountId(accountId);
        setLogsModal(true);
        setUpdateModal(false);
        setConfirmModal(false);
    };

    const handleKasaCıkarOn = (accountId, accountBalance) => {
        setSelectedAccountId(accountId);
        setSelectedAccountBalance(accountBalance);
        setShowKasaCıkar(true);
    };

    const handleDeleteBank = async (accountId) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${BASE_URL}/bank/${accountId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            fetchData();
            setConfirmModal(null);
        } catch (error) {
            console.error("Error deleting bank account:", error);
        }
    };

    const formatIBAN = (accountNumber) => {
        let cleanedIBAN = accountNumber.replace(/\s+/g, '');
        return cleanedIBAN.match(/.{1,4}/g).join(' ');
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
            </div>
        );
    }

    const filteredAccounts = users.filter(account =>
        account.accountName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        account.bankName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        account.accountNumber.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="container-fluid p-3 mx-auto">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                  {/* Search and Refresh Section */}
                  <div className="p-3 bg-gray-300">
                    <div className="flex items-center space-x-4">
                        <div className="flex-1">
                            <input
                                type="search"
                                placeholder="Hesap Ara..."
                                className="w-full px-4 py-1 text-sm rounded-lg border focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button
                            onClick={fetchData}
                            className="px-4 py-1 text-xs bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors"
                        >
                            Yenile
                        </button>
                    </div>
                </div>
                {error && (
                    <div className="p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                        <p className="font-medium">Error</p>
                        <p>{error}</p>
                    </div>
                )}
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-800">
                            <tr>
                                <th className="px-4 py-2 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">ID</th>
                                <th className="px-6 py-2 text-left text-xs font-medium text-slate-200  uppercase tracking-wider">Hesap Sahibi</th>
                                <th className="px-6 py-2 text-left text-xs font-medium text-slate-200  uppercase tracking-wider">Banka</th>
                                <th className="px-6 py-2 text-center text-xs font-medium text-slate-200  uppercase tracking-wider">IBAN</th>
                                <th className="px-6 py-2 text-left text-xs font-medium text-slate-200  uppercase tracking-wider">Miktar</th>
                                <th className="px-6 py-2 text-left text-xs font-medium text-slate-200  uppercase tracking-wider">Oluşturulma Tarihi</th>
                                <th className="px-1 py-2 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">Aktif</th>
                                <th className="px-1 py-2 text-center text-xs font-medium text-slate-200  uppercase tracking-wider">Yatırım</th>
                                <th className="px-1 py-2 text-center text-xs font-medium text-slate-200  uppercase tracking-wider">Çekim</th>
                                <th className="px-1 py-2 text-center text-xs font-medium text-slate-200  uppercase tracking-wider">Bloke</th>
                                <th className="px-1 py-2 text-center text-xs font-medium text-slate-200  tracking-wider">YATIRIM TUTAR (min/max)</th>
                                <th className="px-1 py-2 text-center text-xs font-medium text-slate-200  tracking-wider">ÇEKİM TUTAR (min/max)</th>
                                <th className="px-1 py-2 text-center text-xs font-medium text-slate-200  uppercase tracking-wider">Max Hesap Bakiye</th>
                                <th className="px-1 py-2 text-center text-xs font-medium text-slate-200  uppercase tracking-wider">Log</th>
                                <th className="px-1 py-2 text-center text-xs font-medium text-slate-200  uppercase tracking-wider">Kasa Takviye</th>
                                <th className="px-1 py-2 text-center text-xs font-medium text-slate-200  uppercase tracking-wider">Detaylar</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredAccounts.filter(account => account.isInvest === true).map((account) => (
                                <tr key={account.id} className="hover:bg-gray-100">
                                    <td className="px-6 py-4 whitespace-nowrap bg-blue-300 text-xs font-medium text-gray-900">
                                        {account.id}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap bg-blue-200 text-xs text-gray-900">
                                        {account.accountName}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs bg-blue-200  text-gray-900">
                                        {account.bankName}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap font-mono text-xs  bg-blue-200  text-gray-500">
                                        TR {formatIBAN(account.accountNumber)}
                                    </td>
                                    <td className="px-6 py-4 bg-blue-200  whitespace-nowrap">
                                        <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${account.accountBalance === 0 ? 'bg-gray-300 text-gray-700' :
                                            account.accountBalance < 5000 ? 'bg-yellow-100 text-yellow-800' :
                                                'bg-green-100 text-green-800'
                                            }`}>
                                            {Number(account.accountBalance).toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ₺
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowra bg-blue-200 p text-xs text-gray-500">
                                        {new Date(account.created_at).toLocaleString("tr-TR")}
                                    </td>
                                    <td className="px-3 py-4 bg-blue-200  whitespace-nowrap text-center">
                                        <div className="flex items-center justify-center space-x-2">
                                            <span className={`px-2 py-1 text-xs rounded-full ${account.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                }`}>
                                                {account.isActive ? 'Evet' : 'Hayır'}
                                            </span>
                                        </div>
                                    </td>

                                    <td className="px-3 py-4 bg-blue-200  whitespace-nowrap text-center">
                                        <div className="flex items-center justify-center space-x-2">
                                            <span className={`px-2 py-1 text-xs rounded-full ${account.isInvest ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                }`}>
                                                {account.isInvest ? 'Evet' : 'Hayır'}
                                            </span>
                                        </div>
                                    </td>

                                    <td className="px-3 py-4 bg-blue-200  whitespace-nowrap text-center">
                                        <div className="flex items-center justify-center space-x-2">
                                            <span className={`px-2 py-1 text-xs rounded-full ${account.isPull ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                }`}>
                                                {account.isPull ? 'Evet' : 'Hayır'}
                                            </span>
                                        </div>
                                    </td>

                                    <td className="px-3 py-4 bg-blue-200  whitespace-nowrap text-center">
                                        <div className="flex items-center justify-center space-x-2">
                                            <span className={`px-2 py-1 text-xs rounded-full ${account.isBlocked ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                                }`}>
                                                {account.isBlocked ? 'Evet' : 'Hayır'}
                                            </span>
                                        </div>
                                    </td>

                                    <td className='px-2 py-4  bg-blue-200  whitespace-nowrap text-center '>
                                        <h2 className='text-xs text-black rounded-md'>{account.minInvest}₺</h2>
                                        <h2 className='text-xs text-black rounded-md'>{account.maxInvest}₺</h2>
                                    </td>

                                    <td className='px-2 py-4 bg-blue-200  whitespace-nowrap text-center'>
                                        <h2 className=' text-xs text-black rounded-md'>{account.minPull}₺</h2>
                                        <h2 className=' text-xs text-black rounded-md'>{account.maxPull}₺</h2>
                                    </td>


                                    <td className='px-1 bg-blue-200  py-4 whitespace-nowrap text-center'>
                                        {<h2 className=' text-xs text-black rounded-md'>{account.maxAmount}₺</h2>
                                        }</td>


                                        <td className='px-1 bg-blue-200  py-4 whitespace-nowrap'>
                                        <div className='flex items-center justify-center gap-2'>
                                            <button
                                                onClick={() => handleKasaLogs(account.accountId)}
                                                className="px-1.5 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-xs"
                                            >
                                                Loglar
                                            </button>
                                        </div>
                                    </td>


                                    <td className='px-2 py-4  bg-blue-200 whitespace-nowrap'>
                                        <div className='flex items-center flex-col justify-center gap-2'>
                                            <button
                                                onClick={() => handleKasaTakviyeOn(account.accountId, account.accountBalance)}
                                                className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors text-xs"
                                            >
                                                Ekle
                                            </button>
                                            <button
                                                onClick={() => handleKasaCıkarOn(account.accountId, account.accountBalance)}
                                                className="px-3 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors text-xs"
                                            >
                                                Çıkar
                                            </button>
                                        </div>
                                    </td>

                                

                                    <td className="px-1 py-4 bg-blue-200  whitespace-nowrap text-center">
                                        <div className="flex items-center flex-col gap-2 justify-center space-x-2">
                                            <button
                                                onClick={() => {
                                                    setSelectedAccountId(account.accountId);
                                                    setUpdateModal(true);
                                                }}
                                                className="px-2 py-1 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition-colors text-xs"
                                            >
                                                Güncelle
                                            </button>
                                            <div className='flex flex-row items-center justify-center gap-1'>
                                                <button
                                                    onClick={() => {
                                                        setSelectedAccountId(account.accountId);
                                                        setConfirmModal(true);
                                                    }}
                                                    className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors text-xs"
                                                >
                                                    Sil
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setSelectedAccountId(account.accountId);
                                                        setConfirmModal(true);
                                                    }}
                                                    className="px-2 py-1 bg-yellow-800 text-white rounded-md hover:bg-yellow-900 transition-colors text-xs"
                                                >
                                                    İade Et
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {filteredAccounts.length === 0 && (
                    <div className="text-center py-8 text-gray-500">
                        Hesap Bulunamadı!
                    </div>
                )}
            </div>

            {/* Modals */}
            {confirmModal && (
                <ConfirmBox
                    show={confirmModal}
                    title="Bu banka hesabını silmek istediğinizden emin misiniz?"
                    onSuccess={() => handleDeleteBank(selectedAccountId)}
                    onClose={() => {
                        setConfirmModal(false);
                        setSelectedAccountId(null);
                    }}
                />
            )}

            {updateModal && (
                <BankaUpdateForm
                    show={updateModal}
                    accountId={selectedAccountId}
                    title="Banka Hesabı Güncelle"
                    onClose={() => {
                        setUpdateModal(false);
                        setSelectedAccountId(null);
                    }}
                />
            )}

            {logsModal && (
                <Logs
                    show={logsModal}
                    title="Banka Hesabı Logları"
                    accountId={selectedAccountId}
                    onClose={() => {
                        setLogsModal(false);
                        setSelectedAccountId(null);
                    }}
                />
            )}

            <KasaEkleBox
                show={showKasaEkle}
                onClose={() => setShowKasaEkle(false)}
                accountId={selectedAccountId}
                accountBalance={selectedAccountBalance}
                onSuccess={fetchData}
            />

            <KasaCıkarBox
                show={showKasaCıkar}
                onClose={() => setShowKasaCıkar(false)}
                accountId={selectedAccountId}
                accountBalance={selectedAccountBalance}
                onSuccess={fetchData}
            />
        </div>
    );
};

export default YatırımYapılabilirHesapForm;