import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GiQuickSlash } from "react-icons/gi";

const BASE_URL = process.env.REACT_APP_API_URL;

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [totpCode, setTotpCode] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState('login'); // 'login', 'setup2fa', 'verify2fa', 'enterCode'
  const [qrCode, setQrCode] = useState('');
  const [secret, setSecret] = useState('');
  const [tempToken, setTempToken] = useState('');
  const navigate = useNavigate();

  const handleNormalLogin = async () => {
    try {
      const loginUser = await axios.post(`${BASE_URL}/token`, new URLSearchParams({
        grant_type: 'password',
        username,
        password,
        client_id: 'your_client_id',
        client_secret: 'your_client_secret',
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const token = loginUser.data.access_token;
      setTempToken(token);

      // Check 2FA status
      const response = await axios.get(`${BASE_URL}/2fa-status`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }

      });

      if (!response.data.enabled) {
        // 2FA not enabled, show setup screen
        await handle2FASetup(token);
        setStep('setup2fa');
      } else if (!response.data.verified) {
        // 2FA enabled but not verified
        setStep('verify2fa');
      } else if (response.data.enabled && response.data.verified) {
        // 2FA fully set up, show code input
        setStep('enterCode');
      } else {
        // No 2FA required
        localStorage.setItem('token', token);
        navigate('/');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError(err.response?.data?.detail || 'Bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  const handle2FASetup = async (token) => {
    try {
      // Enable 2FA and get QR code
      const enableResponse = await axios.post(`${BASE_URL}/enable-2fa`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setSecret(enableResponse.data.secret);

      // Get QR code
      const qrResponse = await axios.get(`${BASE_URL}/2fa-qr-code`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob'
      });

      const qrUrl = URL.createObjectURL(qrResponse.data);
      setQrCode(qrUrl);
    } catch (err) {
      console.error('2FA setup error:', err);
      setError(err.response?.data?.detail || '2FA kurulumunda hata oluştu.');
    }
  };

  const handleVerify2FA = async () => {
    try {
      // Use URLSearchParams for the verification request
      const params = new URLSearchParams();
      params.append('code', totpCode.replace(/\s/g, ''));

      await axios.post(
        `${BASE_URL}/verify-2fa`,
        params,
        {
          headers: {
            'Authorization': `Bearer ${tempToken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          }
        }
      );
      setStep('enterCode');
      setTotpCode('');
      setError('');
    } catch (err) {
      console.error('2FA verification error:', err);
      if (err.response?.data?.detail) {
        if (Array.isArray(err.response.data.detail)) {
          setError(err.response.data.detail[0].msg);
        } else {
          setError(err.response.data.detail);
        }
      } else {
        setError('Doğrulama başarısız oldu. Lütfen tekrar deneyin.');
      }
    }
  };

  const handleTOTPLogin = async () => {
    try {
      const params = new URLSearchParams();
      params.append('username', username);
      params.append('password', password);
      params.append('totp_code', totpCode.replace(/\s/g, ''));

      const response = await axios.post(
        `${BASE_URL}/login-2fa`,
        params,
        {
          headers: {
            'accept': 'application/json'
          }
        }
      );

      const token = response.data.access_token;
      localStorage.setItem('token', token);


      navigate('/');
    } catch (err) {
      console.error('2FA login error:', err);
      setError(err.response?.data?.detail || '2FA kodu geçersiz.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (step === 'login') {
      await handleNormalLogin();
    } else if (step === 'verify2fa' || step === 'setup2fa') {
      await handleVerify2FA();
    } else if (step === 'enterCode') {
      await handleTOTPLogin();
    }
  };

  const renderStep = () => {
    switch (step) {
      case 'setup2fa':
      case 'verify2fa':
        return (
          <>
            <div className="mb-4 text-center">
              {step === 'setup2fa' && (
                <>
                  <p className="text-white mb-2">Authenticator'ı kurun ve QR kodu tarayın</p>
                  {qrCode && (
                    <img
                      src={qrCode}
                      alt="2FA QR Code"
                      className="mx-auto mb-2 w-48 h-48"
                    />
                  )}
                  <p className="text-xs text-gray-300 mb-2">
                    Manuel giriş için kod: {secret}
                  </p>
                </>
              )}
              <input
                type="text"
                className="p-2.5 w-56 text-center pl-2 text-xs tracking-wide rounded-md"
                placeholder="Doğrulama Kodunu Girin"
                value={totpCode}
                onChange={(e) => setTotpCode(e.target.value)}
                maxLength="6"
                required
              />
            </div>
          </>
        );

      case 'enterCode':
        return (
          <input
            type="text"
            className="p-2.5 w-56 text-center pl-2 text-xs tracking-wide rounded-md"
            placeholder="Authenticator Kodunuzu Giriniz"
            value={totpCode}
            onChange={(e) => setTotpCode(e.target.value)}
            maxLength="6"
            required
          />
        );

      default:
        return (
          <>
            <input
              type="text"
              className="p-2.5 w-56 text-center pl-2 text-xs tracking-wide rounded-md"
              placeholder="Kullanıcı adı ya da E-Posta"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <input
              type="password"
              className="p-2.5 text-center tracking-wide pl-2 w-56 text-xs rounded-md"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Şifre"
              required
            />
          </>
        );
    }
  };

  const getButtonText = () => {
    switch (step) {
      case 'setup2fa':
        return 'Kurulumu Tamamla';
      case 'verify2fa':
        return 'Doğrula';
      case 'enterCode':
        return 'Giriş Yap';
      default:
        return 'Giriş Yap';
    }
  };

  return (
    <div>
      <div className="flex items-start border border-hidden p-10 mb-12 rounded-md login-form bg-black bg-opacity-55 justify-center" style={{ boxShadow: '4px 5px 35px white' }}>
        <form onSubmit={handleSubmit} method="POST" className="cursor-pointer">
          <div className="flex flex-col gap-5 items-center pb-12 mx-2 justify-center">
            <div className="flex flex-row items-center pl-5 w-full p-5 justify-center gap-0.5">
              <GiQuickSlash size={40} className="text-emerald-600" />
              <a href="/login">
                <h1 className="text-5xl font-light text-white">
                  <span className="text-emerald-500 font-semibold">Dem</span>
                  <span>Fix</span>
                </h1>
              </a>
            </div>

            <h1 className="text-base font-light text-start text-white">
              {step === 'login' ? 'Hesabına Giriş Yap' :
                step === 'setup2fa' ? '2FA Kurulumu' :
                  step === 'verify2fa' ? '2FA Doğrulama' :
                    '2FA Kodu Girin'}
            </h1>

            {renderStep()}

            {error && (
              <div className="text-red-500 text-xs text-center">
                {error}
              </div>
            )}

            <button
              type="submit"
              className="rounded-xl p-2 bg-black bg-opacity-50 hover:bg-emerald-600 border-emerald-600 border-2 transition-all mt-4 w-36 font-semibold tracking-wider text-sm hover:ease-in-out duration-700 text-white"
            >
              {getButtonText()}
            </button>

            {step !== 'login' && (
              <button
                type="button"
                onClick={() => {
                  setStep('login');
                  setTotpCode('');
                  setError('');
                }}
                className="text-emerald-500 text-xs hover:text-emerald-400"
              >
                Geri Dön
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;