import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'


function Blacklist() {
  return (
    <div>
      <Header />
      <NavBar />
      <div className='top-0 bottom-0 right-0 left-0 h-screen'>
      <div className='flex flex-row flex-wrap justify-start gap-4'>

        <div></div>

      </div>
    </div>
    </div>
  )
}

export default Blacklist
