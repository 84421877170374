import React from 'react'
import Header from '../components/Header.jsx'
import NavBar from '../components/NavBar.jsx'
import Main from '../components/Main.jsx'
import '../App.css'
import '../index.css'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'



function Home() {

  const navigate = useNavigate();

  useEffect(() => {
    
    const token = localStorage.getItem('token');
    if (token == null) {
      navigate('/login'); 
    }
  }, [navigate]);

  return (
    <>
    <div className='İndex'>
      <Header />
      <NavBar />
      <Main/>
    </div>
    </>
  
  )
}

export default Home


