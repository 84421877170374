import { useState, useEffect } from 'react';
import axios from 'axios';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaCopy } from "react-icons/fa";
import { AiFillCheckSquare } from "react-icons/ai";
import { GiQuickSlash } from "react-icons/gi";

const BASE_URL = process.env.REACT_APP_API_URL;

function ParavanForm() {
  
  const [loading, setLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [error, setError] = useState(null);
  const [investment, setInvestment] = useState(null);
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [transferConfirmed, setTransferConfirmed] = useState(false);
  const [formData, setFormData] = useState({
    refId: '',
    fullName: '',
    balance: '',
    username: '',
    method: '',
    note: ''
  });

  // Get and validate URL parameters on component mount
  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search);
      const refId = params.get('ref');
      const username = params.get('user');
      if (refId && username) {
        setFormData(prev => ({
          ...prev,
          refId: refId,
          username: username
        }));
      }
    } catch (err) {
      console.error('Error processing URL parameters:', err);
    }
  }, []);

  // Clipboard copy function
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // IBAN formatting
  const formatIBAN = (accountNumber) => {
    return accountNumber?.replace(/\s+/g, '').match(/.{1,4}/g)?.join(' ') || '';
  };

  // Form input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'balance'
        ? value.replace(/[^\d]/g, '').replace(/^0+/, '')
        : value
    }));
  };

  // Form validation
  const validateForm = () => {
    const errors = [];
    if (!formData.fullName.trim()) errors.push("Ad Soyad alanı zorunludur");
    if (!formData.balance || isNaN(formData.balance) || parseFloat(formData.balance) <= 0)
      errors.push("Geçerli bir miktar giriniz");
    if (!formData.refId) errors.push("Site RefID'niz eksik, bağlantı yok.");
    if (!formData.username) errors.push("Site kullanıcı adınız eksik, bağlantı yok.");
    return errors;
  };

  // Investment creation
  const createInvestment = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      const params = new URLSearchParams();
      Object.entries({
        refId: formData.refId.trim(),
        fullName: formData.fullName.trim(),
        balance: parseFloat(formData.balance),
        username: formData.username.trim(),
        method: formData.method || 'Online',
        note: formData.note.trim() || ''
      }).forEach(([key, value]) => {
        params.append(key, value);
      });

      const response = await axios.post(`${BASE_URL}/investment/?${params.toString()}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      
      setInvestment(response.data);
      setShowAccountDetails(true);
    } catch (error) {
      let errorMessage = 'Yatırım oluşturulurken bir hata oluştu.';
      if (error.response?.data?.detail) {
        errorMessage += ` ${error.response.data.detail}`;
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Initial form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setError(validationErrors.join(', '));
      return;
    }
    await createInvestment();
  };

  // Poll investment status
  const pollInvestmentStatus = async () => {
    if (!investment?.id) return;
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_URL}/investment/${investment.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      const status = response.data.investmentStatus;
      if (status === 'Onaylandı') {
        setSubmissionStatus('approved');
        return true;
      } else if (status === 'İptal') {
        setSubmissionStatus('cancelled');
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error checking status:', error);
      setSubmissionStatus('error');
      return true;
    }
  };

  const handleConfirmTransfer = async () => {
    if (!transferConfirmed) {
      setTransferConfirmed(true);
      setSubmissionStatus('pending');
      
      const pollStatus = async () => {
        const shouldStop = await pollInvestmentStatus();
        if (!shouldStop) {
          setTimeout(pollStatus, 120000);
        }
      };
      
      pollStatus();
    }
  };

  // Render initial form
  const renderForm = () => (
    <form onSubmit={handleSubmit} method="post" className="cursor-pointer w-full">
      <div className="flex flex-col gap-6 rounded-lg p-5 h-72 w-64 items-center mx-2 justify-center">
        <h1 className="text-sm px-4 text-center text-white font-thin animate-pulse">
          {formData.username ? `Yatırım Sayfamıza Hoşgeldin, ${formData.username}!` : 'Yatırım Sayfamıza Hoşgeldiniz!'}
        </h1>
        <input
          type="text"
          name="fullName"
          className="p-2.5 w-56 text-center pl-2 text-xs tracking-wide rounded-md"
          placeholder="Adınız Soyadınız"
          value={formData.fullName}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="balance"
          className="p-2.5 text-center tracking-wide pl-2 w-56 text-xs rounded-md"
          value={formData.balance}
          onChange={handleChange}
          placeholder="Yatırım Tutarı"
          required
        />
        <button
          type="submit"
          className="rounded-xl p-2 bg-black bg-opacity-50 hover:bg-emerald-600 border-emerald-600 border-2 transition-all mt-4 w-32 font-semibold tracking-wider text-xs hover:ease-in-out duration-700 text-white"
        >
          Sonraki Adım
        </button>
      </div>
    </form>
  );



  const renderAccountDetails = () => (
    <div className="flex flex-col gap-6 items-center border-2 border-gray-300 p-6 mt-6 mx-4 bg-gradient-to-br from-emerald-50 to-white shadow-lg w-96">
      <div className="w-full flex flex-col gap-4">
        <div className="text-start flex flex-col items-start">
          <h2 className="text-xs font-semibold font-sans text-gray-700 mb-2">Adınız Soyadınız</h2>
          <p className="w-full border-2 border-gray-300 font-sans bg-white text-gray-800 font-medium text-xs rounded-lg p-2 shadow-sm">
            {investment.fullName}
          </p>
        </div>
        <div className="text-start flex flex-col relative items-start">
          <h2 className="text-xs font-semibold font-sans text-gray-700 mb-2">Hesap Sahibi Adı</h2>
          <p className="w-full border-2 border-gray-300 bg-white font-sans text-gray-800 font-medium text-xs rounded-lg p-2 shadow-sm">
            {investment.accountName}
          </p>
          <FaCopy
            size={15}
            className="absolute cursor-pointer right-2 bottom-2.5 text-gray-500 hover:text-gray-900 transition duration-200"
            onClick={() => handleCopy(investment.accountName)}
          />
        </div>
        <div className="text-start flex flex-col relative items-start">
          <h2 className="text-xs font-semibold font-sans text-gray-700 mb-2">IBAN</h2>
          <p className="w-full border-2 border-gray-300 font-sans bg-white text-gray-800 font-medium text-xs rounded-lg p-2 shadow-sm">
            TR {formatIBAN(investment.accountNumber)}
          </p>
          <FaCopy
            size={15}
            className="absolute cursor-pointer right-2 bottom-2.5 text-gray-500 hover:text-gray-900 transition duration-200"
            onClick={() => handleCopy(`TR ${formatIBAN(investment.accountNumber)}`)}
          />
        </div>
        <div className="border-2 p-2 my-1">
          <ul className="font-sans text-black flex flex-col list gap-2 text-[9.2px]">
            <span className="text-red-600 font-bold underline text-[10px]">Önemli Notlar:</span>
            <li className="pl-2">
              ~ Lütfen, gönderiminizi yaptıktan sonra "Gönderim Yaptım" butonunu seçiniz.
            </li>
            <li className="pl-2">
              ~ İşlem onaylanma süresi 3-15 dakikadır. Dilerseniz, "Gönderim Yaptım" butonunu seçtikten sonra, sayfayı kapatabilirsiniz.
            </li>
            <li className="pl-2">
              ~ Anlayışınız için teşekkür eder, iyi şanslar dileriz.
            </li>
          </ul>
        </div>
        <button
          onClick={handleConfirmTransfer}
          disabled={transferConfirmed}
          className={`px-4 py-2 w-full text-xs font-semibold text-white rounded-lg transition duration-200 ease-in-out shadow-md mt-6 
            ${transferConfirmed 
              ? 'bg-gray-400 cursor-not-allowed' 
              : 'bg-emerald-600 hover:bg-emerald-700 focus:ring-2 focus:ring-emerald-500 focus:ring-opacity-50'}`}
        >
          {transferConfirmed ? 'Gönderim Onaylandı' : 'Gönderimi Yaptım'}
        </button>
      </div>
    </div>
  );


  const renderStatusScreen = () => {
    return (
      <div className="p-5 h-72 w-64 flex flex-col items-center justify-center rounded-lg relative mx-2 bg-transparent">
        <AiFillCheckSquare className="text-emerald-600 mb-3" size={45} />
        <p className="text-base font-mono text-white tracking-wider text-center">
          YATIRIM TALEBİNİZ
        </p>
        <p className="text-base font-mono text-white tracking-wider text-center mb-8">
          BAŞARIYLA GÖNDERİLMİŞTİR.
        </p>
        <div className='absolute bottom-2 mt-8 text-xs flex flex-col items-start gap-2 text-center font-sans px-4 font-extralight text-white'>
        <p className='text-xs  text-center text-white font-sans'>- Tahmini tamamlanma süresi 3-15 dakikadır. Dilerseniz sayfayı güvenle kapatabilirsiniz.</p>
        <h2 className="text-xs text-center font-sans px-4 font-extralight text-white">
          Teşekkür eder, iyi şanslar dileriz.
        </h2>
        </div>

       
      </div>
    );
  };
  

  // Render loading state
  const renderLoading = () => (
    <div className="p-5 h-72 w-64 flex flex-col items-center justify-center rounded-lg relative mx-2">
      <AiOutlineLoading3Quarters className="text-white mb-4 animate-spin duration-500 delay-200" size={35} />
      <p className="text-base font-mono text-white text-center tracking-wider font-bold animate-pulse">
        LÜTFEN BEKLEYİNİZ.
      </p>
      <h2 className="absolute bottom-2 text-xs text-center px-4 font-extralight text-white">
        İşleminiz kontrol ediliyor, lütfen sayfayı kapatmayınız.
      </h2>
    </div>
  );

  return (
    <div style={{ boxShadow: '4px 5px 35px white' }} className="flex items-center border border-hidden p-10 mb-12 rounded-md login-form bg-black bg-opacity-65 justify-center">
      <div className="flex flex-col items-center">
        <div className="flex flex-row items-center pl-5 w-full p-5 justify-center gap-0.5">
          <GiQuickSlash size={40} className="text-emerald-600" />
          <h1 className="text-5xl font-light text-white">
            <span className="text-emerald-500 font-semibold">Dem</span>
            <span>Fix</span>
          </h1>
        </div>
        {submissionStatus ? renderStatusScreen() :
          loading ? renderLoading() :
            showAccountDetails ? renderAccountDetails() :
              renderForm()}
        {error && (
          <div className="text-red-500 text-xs mt-4">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}
export default ParavanForm;