import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProgressBox from './ConfirmBox';
import ConfirmBox from './ConfirmBox';
const BASE_URL = process.env.REACT_APP_API_URL;
const ROWS_PER_PAGE = 30;
const AccountSelectionModal = ({ show, onClose, onConfirm, accounts }) => {
    const [selectedAccount, setSelectedAccount] = useState('');
    const [, setMessage] = useState('');
    if (!show) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                <h3 className="text-lg font-medium mb-4">Çekimi Yapacağınız Hesabı Seçin</h3>
                <select
                    className="w-full p-2 mb-4 border rounded-md text-sm"
                    value={selectedAccount}
                    onChange={(e) => setSelectedAccount(e.target.value)}
                >
                    <option value="">Seçiniz</option>
                    {accounts.map((account) => (
                        <option
                            key={account.accountId}
                            value={`${account.accountName}|${account.accountNumber}`}
                        >
                            {account.accountName} - {account.bankName} - {account.accountNumber}
                        </option>
                    ))}
                </select>
                <div className="flex justify-end gap-2">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-xs text-gray-600 border rounded-md hover:bg-gray-50"
                    >
                        İptal
                    </button>
                    <button
                        onClick={() => {
                            if (selectedAccount) {
                                const [accountName, accountNumber] = selectedAccount.split('|');
                                onConfirm(accountName, accountNumber);
                                setMessage('Çekim Seçtiğiniz Hesaptan Onaylanmıştır.');
                            }
                        }}
                        disabled={!selectedAccount}
                        className={`px-4 py-2 text-xs text-white rounded-md ${selectedAccount
                            ? 'bg-emerald-500 hover:bg-emerald-600 cursor-pointer'
                            : 'bg-gray-400 cursor-not-allowed'
                            }`}
                    >
                        Onayla
                    </button>
                </div>
            </div>
        </div>
    );
};
const WithdrawalTable = ({ filters, autoUpdate }) => {
    const [withdrawals, setWithdrawals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');
    const [refs, setRefs] = useState({});
    const [users, setUsers] = useState({});
    const [accounts, setAccounts] = useState([]);
    const [updateLoading, setUpdateLoading] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showAccountSelection, setShowAccountSelection] = useState(false);
    const [selectedWithdrawalId, setSelectedWithdrawalId] = useState(null);
    const [selectedWithdrawalForApproval, setSelectedWithdrawalForApproval] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filteredWithdrawals, setFilteredWithdrawals] = useState([]);
    const applyFilters = (withdrawals) => {
        const filtered = withdrawals.filter(withdrawal => {
            // 1. Ref Filter
            if (filters.refIds && filters.refIds.length > 0) {
                if (!filters.refIds.includes(withdrawal.refId)) {
                    return false;
                }
            }
            // 2. Account Number Filter
            if (filters.accountNumbers && filters.accountNumbers.length > 0) {
                if (!filters.accountNumbers.includes(withdrawal.accountNumber)) {
                    return false;
                }
            }
            // 3. Amount Range Filter
            if (filters.minAmount && !isNaN(filters.minAmount)) {
                if (parseFloat(withdrawal.balance) < parseFloat(filters.minAmount)) {
                    return false;
                }
            }
            if (filters.maxAmount && !isNaN(filters.maxAmount)) {
                if (parseFloat(withdrawal.balance) > parseFloat(filters.maxAmount)) {
                    return false;
                }
            }
            // 4. Date Range Filter
            if (filters.startDate || filters.endDate) {
                const withdrawalDate = new Date(withdrawal.transactionDate);
                if (filters.startDate) {
                    const startDate = new Date(filters.startDate);
                    startDate.setHours(0, 0, 0, 0);
                    if (withdrawalDate < startDate) {
                        return false;
                    }
                }
                if (filters.endDate) {
                    const endDate = new Date(filters.endDate);
                    endDate.setHours(23, 59, 59, 999);
                    if (withdrawalDate > endDate) {
                        return false;
                    }
                }
            }
            // 5. Search Term Filter
            if (searchTerm) {
                const searchLower = searchTerm.toLowerCase();
                const matchesSearch = (
                    withdrawal.id.toString().includes(searchLower) ||
                    (refs[withdrawal.refId] || '').toLowerCase().includes(searchLower) ||
                    withdrawal.fullName.toLowerCase().includes(searchLower) ||
                    withdrawal.username.toLowerCase().includes(searchLower) ||
                    withdrawal.accountNumber.includes(searchLower) ||
                    withdrawal.targetAccountNumber.includes(searchLower)
                );
                if (!matchesSearch) {
                    return false;
                }
            }
            // 6. Status Filter
            if (statusFilter !== 'all') {
                if (withdrawal.withdrawalStatus !== statusFilter) {
                    return false;
                }
            }
            return true;
        });
        setFilteredWithdrawals(filtered);
        setTotalPages(Math.ceil(filtered.length / ROWS_PER_PAGE));
        setCurrentPage(1); // Reset to first page when filters change
        return filtered;
    };
    const getCurrentPageWithdrawals = () => {
        const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
        const endIndex = startIndex + ROWS_PER_PAGE;
        return filteredWithdrawals.slice(startIndex, endIndex);
    };
    const fetchWithdrawals = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/withdrawal/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            let sortedWithdrawals = response.data.data.sort((a, b) =>
                new Date(b.transactionDate) - new Date(a.transactionDate)
            );
            sortedWithdrawals = applyFilters(sortedWithdrawals);
            setWithdrawals(sortedWithdrawals);
            setError(null);
        } catch (error) {
            console.error("Error fetching withdrawals:", error);
            setError("Çekimler yüklenirken bir hata oluştu. Lütfen tekrar deneyin.");
        } finally {
            setLoading(false);
        }
    };
    const fetchRefs = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/refs/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const refsMap = {};
            response.data.data.forEach(ref => {
                refsMap[ref.refId] = ref.refName;
            });
            setRefs(refsMap);
        } catch (error) {
            console.error("Error fetching refs:", error);
        }
    };
    const fetchUsers = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/users`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const usersMap = {};
            response.data.data.forEach(user => {
                usersMap[user.id] = user.username;
            });
            setUsers(usersMap);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };
    const fetchCurrentUser = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/users/me`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setCurrentUser(response.data);
        } catch (error) {
            console.error("Error fetching current user:", error);
        }
    };
    const fetchAccounts = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/bank/accounts`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setAccounts(response.data.data);
        } catch (error) {
            console.error("Error fetching accounts:", error);
        }
    };
    useEffect(() => {
        fetchWithdrawals();
        fetchRefs();
        fetchUsers();
        fetchAccounts();
        fetchCurrentUser();
        let interval;
        if (autoUpdate) {
            interval = setInterval(() => {
                fetchWithdrawals();
            }, 60000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [filters, autoUpdate, searchTerm, statusFilter]);
    const handleTakeOwnership = async (withdrawalId) => {
        const token = localStorage.getItem('token');
        try {
            setUpdateLoading(withdrawalId);
            await axios.post(`${BASE_URL}/withdrawal/${withdrawalId}/take-ownership`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            await fetchWithdrawals();
            setShowModal(false);
        } catch (error) {
            console.error("Error taking ownership:", error);
            setError("Çekim sahiplenirken bir hata oluştu.");
        } finally {
            setUpdateLoading(null);
        }
    };
    const handleStatusUpdate = async (withdrawalId, newStatus, accountName = null, accountNumber = null) => {
        const token = localStorage.getItem('token');
        setUpdateLoading(withdrawalId);
        try {
            const withdrawal = withdrawals.find(w => w.id === withdrawalId);
            if (!withdrawal.userID) {
                await handleTakeOwnership(withdrawalId);
            }
            const updateData = {
                withdrawalStatus: newStatus,
                note: newStatus === 'Onaylandı'
                    ? 'Çekim onaylandı'
                    : newStatus === 'İptal'
                        ? 'Çekim iptal edildi'
                        : 'Çekim beklemede',
            };
            if (accountName && accountNumber) {
                updateData.accountName = accountName;
                updateData.accountNumber = accountNumber;
            }
            await axios.patch(`${BASE_URL}/withdrawal/${withdrawalId}`, updateData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            await fetchWithdrawals();
            setShowModal3(false);
            setShowAccountSelection(false);
        } catch (error) {
            setError("Bu Çekimi Onaylamaya Yetkiniz Yok.");
        } finally {
            setUpdateLoading(null);
        }
    };
    const handleApprovalClick = (withdrawalId) => {
        setSelectedWithdrawalForApproval(withdrawalId);
        setShowAccountSelection(true);
    };
    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 'Onaylandı':
                return 'bg-emerald-600 text-slate-200';
            case 'İptal':
                return 'bg-red-600 text-slate-200';
            case 'Beklemede':
            default:
                return 'bg-yellow-600 text-slate-100';
        }
    };
    const getStatusTableClass = (status) => {
        switch (status) {
            case 'Onaylandı':
                return 'bg-emerald-200 text-white';
            case 'İptal':
                return 'bg-red-200 text-white';
            case 'Beklemede':
            default:
                return 'bg-amber-100 text-white';
        }
    };
    const handleCloseShowModal = () => {
        setShowModal(false);
        setSelectedWithdrawalId(null);
    };
    const handleCloseShowModal3 = () => {
        setShowModal3(false);
        setSelectedWithdrawalId(null);
    };
    const Pagination = () => {
        const pageNumbers = [];
        const maxVisiblePages = 5;

        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        return (
            <div className="flex items-center justify-center space-x-2 my-4">
                <button
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1}
                    className="px-3 py-1 bg-gray-200 rounded-md disabled:opacity-50"
                >
                    &#171;
                </button>
                <button
                    onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                    disabled={currentPage === 1}
                    className="px-3 py-1 bg-gray-200 rounded-md disabled:opacity-50"
                >
                    &#8249;
                </button>
                {pageNumbers.map(number => (
                    <button
                        key={number}
                        onClick={() => setCurrentPage(number)}
                        className={`px-3 py-1 rounded-md ${currentPage === number ? 'bg-emerald-500 text-white' : 'bg-gray-200'} `}

                    >
                        {number}
                    </button>
                ))}

                <button
                    onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                    disabled={currentPage === totalPages}
                    className="px-3 py-1 bg-gray-200 rounded-md disabled:opacity-50"
                >
                    &#8250;
                </button>
                <button
                    onClick={() => setCurrentPage(totalPages)}
                    disabled={currentPage === totalPages}
                    className="px-3 py-1 bg-gray-200 rounded-md disabled:opacity-50"
                >
                    &#187;
                </button>
                <span className="text-sm text-gray-600">
                    Sayfa {currentPage} / {totalPages}
                </span>
            </div>
        );
    };
    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
            </div>
        );
    }
    return (
        <div className="container-fluid mx-auto p-3">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="p-4 bg-gray-300">
                    <div className="flex items-center justify-between space-x-4">
                        <div className="flex-1">
                            <input
                                type="search"
                                placeholder="ID, Site, Kullanıcı Adı veya Hesap No ile ara..."
                                className="w-full px-4 py-2 rounded-lg border text-xs focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <select
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            className="px-4 py-2 border rounded-lg text-xs focus:outline-none focus:ring-2 focus:ring-emerald-500"
                        >
                            <option value="all">Tüm Durumlar</option>
                            <option value="Beklemede">Bekleyenler</option>
                            <option value="Onaylandı">Onaylananlar</option>
                            <option value="İptal">Reddedilenler</option>
                        </select>
                        <button
                            onClick={fetchWithdrawals}
                            className="px-4 py-2 bg-emerald-500 text-white rounded-lg text-xs hover:bg-emerald-600 transition-colors"
                        >
                            Yenile
                        </button>
                    </div>
                </div>
                {error && (
                    <div className="p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                        <p className="font-medium">Hata!</p>
                        <p>{error}</p>
                    </div>
                )}
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-800">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">ID</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Site</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">RefID</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Adı Soyadı</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Miktar</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Kullanıcı Adı</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Yöntem</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Kaynak Hesap</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Kaynak No</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Hedef Hesap</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Hedef No</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">İşlem Tarihi</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Sonuç Tarihi</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">İşlemi Yapan</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">Durum</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">İşlemler</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {getCurrentPageWithdrawals().map((withdrawal) => (
                                <tr key={withdrawal.id} className={`text-xs ${getStatusTableClass(withdrawal.withdrawalStatus)}`}>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{withdrawal.id}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{refs[withdrawal.refId] || '-'}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{withdrawal.id}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{withdrawal.fullName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        <span className={`p-1 border ${withdrawal.balance <= 4999 ? 'bg-yellow-600' : 'bg-emerald-600'} rounded-md text-white`}>
                                            {withdrawal.balance.toLocaleString()} ₺
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{withdrawal.username}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{withdrawal.method}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{withdrawal.accountName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">TR {withdrawal.accountNumber}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{withdrawal.targetAccountName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">TR {withdrawal.targetAccountNumber}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs italic text-gray-500">
                                        {new Date(withdrawal.transactionDate).toLocaleString("tr-TR")}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs italic text-gray-500">
                                        {withdrawal.resultDate && new Date(withdrawal.resultDate).toLocaleString("tr-TR")}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-white">
                                        <span className={`p-1 rounded-lg ${users[withdrawal.userID] ? 'bg-red-400' : 'bg-red-600'}`}>
                                            {users[withdrawal.userID] || 'Seçilmedi'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-center">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(withdrawal.withdrawalStatus)}`}>
                                            {withdrawal.withdrawalStatus}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-center">
                                        <div className="flex items-center justify-center space-x-2">
                                            {withdrawal.withdrawalStatus === 'Beklemede' && (
                                                <>
                                                    {!withdrawal.userID && (
                                                        <button
                                                            onClick={() => {
                                                                setSelectedWithdrawalId(withdrawal.id);
                                                                setShowModal(true);
                                                            }}
                                                            disabled={updateLoading === withdrawal.id}
                                                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-xs"
                                                        >
                                                            {updateLoading === withdrawal.id ? 'İşleniyor...' : 'İşlemi Al'}
                                                        </button>
                                                    )}
                                                    {withdrawal.userID && (
                                                        <div className='flex flex-row items-center justify-center gap-2'>
                                                            {withdrawal.userID === currentUser?.id ? (
                                                                <>
                                                                    <button
                                                                        onClick={() => handleApprovalClick(withdrawal.id)}
                                                                        disabled={updateLoading === withdrawal.id}
                                                                        className="px-3 py-1 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition-colors text-xs"
                                                                    >
                                                                        {updateLoading === withdrawal.id ? 'Onaylanıyor...' : 'Onayla'}
                                                                    </button>
                                                                    <button
                                                                        onClick={() => {
                                                                            setSelectedWithdrawalId(withdrawal.id);
                                                                            setShowModal3(true);
                                                                        }}
                                                                        disabled={updateLoading === withdrawal.id}
                                                                        className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors text-xs"
                                                                    >
                                                                        {updateLoading === withdrawal.id ? 'Reddediliyor...' : 'Reddet'}
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <button
                                                                    onClick={() => {
                                                                        setSelectedWithdrawalId(withdrawal.id);
                                                                        handleTakeOwnership(withdrawal.id);
                                                                    }}
                                                                    disabled={updateLoading === withdrawal.id}
                                                                    className="px-3 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors text-xs"
                                                                >
                                                                    {updateLoading === withdrawal.id ? 'İşlem Alınıyor...' : 'İşlemi Devral'}
                                                                </button>
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                {filteredWithdrawals.length > 0 && <Pagination />}
                {filteredWithdrawals.length === 0 && (
                    <div className="text-center py-6 text-gray-500">
                        Çekim Bulunamadı.
                    </div>
                )}
                {/* Take Ownership Modal */}
                {showModal && (
                    <ProgressBox
                        title="İşlemi almak istiyor musun?"
                        show={showModal}
                        onSuccess={() => handleTakeOwnership(selectedWithdrawalId)}
                        onClose={handleCloseShowModal}
                    />
                )}
                {/* Account Selection Modal */}
                <AccountSelectionModal
                    show={showAccountSelection}
                    onClose={() => setShowAccountSelection(false)}
                    accounts={accounts}
                    onConfirm={(accountName, accountNumber) => {
                        handleStatusUpdate(selectedWithdrawalForApproval, 'Onaylandı', accountName, accountNumber);
                    }}
                />
                {/* Reject Modal */}
                {showModal3 && (
                    <ConfirmBox
                        title="İşlemi reddetmek istiyor musun?"
                        show={showModal3}
                        onSuccess={() => {
                            handleStatusUpdate(selectedWithdrawalId, 'İptal');
                            setShowModal3(false);
                        }}
                        onClose={handleCloseShowModal3}
                    />
                )}
            </div>
        </div>
    );
};

export default WithdrawalTable;