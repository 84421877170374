import React from 'react'
import {useState} from 'react'
import AdminForm from '../components/AdminForm'


function AdminFormButton() {

  const [isFormOpen, setIsFormOpen] = useState(false);


  const openForm = () => {
    setIsFormOpen(!isFormOpen);
  }

  const handleAdminForm = () => {
    setIsFormOpen(!isFormOpen);
  }

  const handleCloseAdmin = () => {
    setIsFormOpen(!isFormOpen);
  }


  return (
    <>
    <div className='p-2 pl-2'>
      <button type='button' onClick={openForm}  className='p-1.5 border px-2  border-white m-1 mt-3 ml-3 rounded-md bg-emerald-500 text-white text-xs'>Admin Oluştur</button>
      {isFormOpen && ( <AdminForm title={"Admin Oluşturma Formu"} show={handleAdminForm} onClose={handleCloseAdmin} />)}
    </div>
    </>
  )
}

export default AdminFormButton
