import React, { useState } from 'react';
import dayjs from 'dayjs';

const DateInput = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    onDateChange({
      startDate: newStartDate,
      endDate: endDate
    });
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    onDateChange({
      startDate: startDate,
      endDate: newEndDate
    });
  };

  return (
    <div className="flex flex-row items-center text-xs gap-2 justify-center">
      <div className="flex items-center gap-1">
        <span className="text-gray-600">Başlangıç:</span>
        <input
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          className="p-0.5 rounded-md text-xs cursor-pointer font-medium bg-white border border-gray-300 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 shadow-sm transition-all duration-200 hover:border-emerald-400"
        />
      </div>
      <div className="flex items-center gap-1">
        <span className="text-gray-600">Bitiş:</span>
        <input
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          className="p-0.5 rounded-md text-xs cursor-pointer font-medium bg-white border border-gray-300 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 shadow-sm transition-all duration-200 hover:border-emerald-400"
        />
      </div>
    </div>
  );
};

export default DateInput;