import React from 'react'
import '../index.css'
import LoginForm from '../components/LoginForm'

function login() {

  return (
   <>
    <div>
    <section className="h-screen login-page relative">
            <article className="container h-full mx-auto flex  items-center justify-center">
                     <LoginForm />
            </article>
    </section>
    </div>
   </>
  )
}

export default login
