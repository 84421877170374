import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AiOutlineLoading3Quarters, AiFillCheckSquare } from "react-icons/ai";
import { FaCopy } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { GiQuickSlash } from "react-icons/gi";
import { FaBitcoin } from "react-icons/fa6";
import { SiEthereum } from "react-icons/si";
import { SiTether } from "react-icons/si";
import { RiXrpLine } from "react-icons/ri";


// Configuration for supported cryptocurrencies
const CRYPTO_CONFIG = {

  tether: {
    name: 'Tether',
    symbol: 'USDT',
    apiId: 'tether',
    icon: <SiTether />
  },

  eth: {
    name: 'Ethereum',
    symbol: 'ETH',
    apiId: 'ethereum',
    icon: <SiEthereum />
  }

};


const TRUST_WALLET_ADDRESS = 'txhsusadajsdı83984d09as0d9ajmmqwe210';
const BASE_URL = process.env.REACT_APP_API_URL;
const MIN_AMOUNT = 100; 
const MAX_AMOUNT = 100000;

const PaymentDetailsScreen = ({
  selectedCrypto,
  cryptoRates,
  formData,
  handleSubmit
}) => {
  const [copied, setCopied] = useState(false);

  const cryptoAmount = formData.balance / cryptoRates[selectedCrypto];

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => console.error('Copy failed:', err));
  };

  return (
    <div className="flex flex-col gap-6 items-center justify-center rounded-md p-3 mt-4 mx-2 text-gray-900 w-96">
      <div className="w-full flex flex-col justify-center gap-3">
        <span className="flex flex-col my-2 text-center">
          <h2 className="text-base text-white">
            Hesabınıza eklenecek tutar: {formData.balance} ₺
          </h2>
          <h2 className="text-base text-white">
            Göndermeniz gereken {CRYPTO_CONFIG[selectedCrypto].symbol} miktarı:
          </h2>
          <h2 className="text-xl text-white font-bold">
            {cryptoAmount.toFixed(6)} {CRYPTO_CONFIG[selectedCrypto].symbol}
          </h2>
        </span>

        <div className="text-start flex flex-col relative items-start justify-center">
          <h2 className="text-xs font-semibold text-white font-sans mb-2">
            Cüzdan Adresi ({CRYPTO_CONFIG[selectedCrypto].symbol})
          </h2>
          <div className="w-full relative">
            <p className="w-full border-2 text-xs bg-white text-black font-semibold rounded-md p-2 pr-8 break-all">
              {TRUST_WALLET_ADDRESS}
            </p>
            <button
              onClick={() => handleCopy(TRUST_WALLET_ADDRESS)}
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
            >
              {copied ? (
                <AiFillCheckSquare className="text-green-600" size={16} />
              ) : (
                <FaCopy className="text-gray-600 hover:text-gray-800" size={16} />
              )}
            </button>
          </div>
        </div>

        <div className="mt-4 flex flex-col items-center gap-2">
          <img
            src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${TRUST_WALLET_ADDRESS}`}
            alt="QR Code"
            className="w-32 h-32 bg-white p-2 rounded-lg"
          />
          <p className="text-xs text-white text-center">
            QR kodu uygulamanızla tarayarak adresi kolayca kopyalayabilirsiniz.
          </p>
        </div>

        <div className="space-y-2 text-xs text-white mt-2">
          <p className="font-semibold">Önemli Notlar:</p>
          <ul className="list-disc pl-4 space-y-1">
            <li>Lütfen sadece {CRYPTO_CONFIG[selectedCrypto].name} ({CRYPTO_CONFIG[selectedCrypto].symbol}) gönderimi yapınız</li>
            <li>Farklı bir kripto para gönderimi yapılması durumunda iade yapılmayacaktır.</li>
            <li>Gönderim sonrası "Gönderimi Yaptım" butonuna tıklayınız</li>
          </ul>
        </div>

        <div className="text-center">
          <button
            className="p-2 py-2.5 text-xs mt-4 rounded-md hover:bg-emerald-400 duration-500 ease-in-out bg-emerald-500 text-white w-full"
            onClick={handleSubmit}
          >
            Gönderimi Yaptım
          </button>
        </div>
      </div>
    </div>
  );
};

const StatusScreen = ({ status }) => {
  const statusConfig = {
    pending: {
      icon: <AiOutlineLoading3Quarters className="animate-spin" size={35} />,
      title: 'İŞLEMİNİZ KONTROL EDİLİYOR',
      message: 'Yatırım talebiniz kontrol ediliyor, lütfen sayfayı kapatmayınız.'
    },
    approved: {
      icon: <AiFillCheckSquare className="text-emerald-600" size={45} />,
      title: 'Yatırımınız Onaylanmıştır',
      message: 'Sayfayı güvenle kapatabilirsiniz.'
    },
    cancelled: {
      icon: <MdError className="text-red-500" size={45} />,
      title: 'Yatırımınız İptal Edilmiştir',
      message: 'Lütfen müşteri hizmetleri ile iletişime geçiniz.'
    },
    error: {
      icon: <MdError className="text-red-500" size={45} />,
      title: 'Bir Hata Oluştu',
      message: 'Lütfen daha sonra tekrar deneyiniz.'
    }
  };

  const config = statusConfig[status];

  return (
    <div className="p-5 h-72 w-64 flex flex-col items-center justify-center rounded-lg relative">
      {config.icon}
      <p className="text-base font-mono text-white text-center tracking-wider mt-4">
        {config.title}
      </p>
      <p className="absolute bottom-2 text-xs text-center px-4 font-light text-white">
        {config.message}
      </p>
    </div>
  );
};


function KriptoYatırımPage() {
  const [loading, setLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [activeAccount, setActiveAccount] = useState(null);
  const [error, setError] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [currentInvestmentId, setCurrentInvestmentId] = useState(null);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [cryptoRates, setCryptoRates] = useState(
    Object.keys(CRYPTO_CONFIG).reduce((acc, key) => ({ ...acc, [key]: 0 }), {})
  );

  const [formData, setFormData] = useState({
    refId: '58aac686-8903-4670-a0ba-a7009684a0fd',
    fullName: 'site.fullName',
    balance: '',
    username: 'site.userName',
    method: 'Iframe',
    note: ''
  });

  // Fetch crypto rates
  useEffect(() => {
    const fetchCryptoRates = async () => {
      try {
        const rates = {};
        const cryptoIds = Object.values(CRYPTO_CONFIG).map(c => c.apiId).join(',');
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=${cryptoIds}&vs_currencies=try`
        );

        for (const [key, config] of Object.entries(CRYPTO_CONFIG)) {
          rates[key] = response.data[config.apiId]?.try || 0;
        }
        setCryptoRates(rates);
      } catch (error) {
        console.error('Error fetching crypto rates:', error);
      }
    };

    fetchCryptoRates();
    const intervalId = setInterval(fetchCryptoRates, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  // Check investment status
  useEffect(() => {
    let intervalId;

    const checkInvestmentStatus = async () => {
      if (!currentInvestmentId) return;

      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${BASE_URL}/investment/${currentInvestmentId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json'
            }
          }
        );

        const status = response.data.investmentStatus;
        if (status === 'Onaylandı') {
          setSubmissionStatus('approved');
          clearInterval(intervalId);
        } else if (status === 'İptal') {
          setSubmissionStatus('cancelled');
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error checking investment status:', error);
        setSubmissionStatus('error');
        clearInterval(intervalId);
      }
    };

    if (submissionStatus === 'pending') {
      intervalId = setInterval(checkInvestmentStatus, 5000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [currentInvestmentId, submissionStatus]);

  const fetchAccounts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/bank/accounts`);
      const sortedAccounts = response.data.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setAccounts(sortedAccounts);
      setError(null);
    } catch (error) {
      console.error("Error fetching bank accounts:", error);
      setError("Banka hesapları yüklenemedi. Lütfen daha sonra tekrar deneyiniz.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  const handleCryptoSelect = (crypto) => {
    setSelectedCrypto(selectedCrypto === crypto ? null : crypto);
  };

  const validateForm = () => {
    const amount = parseFloat(formData.balance);
    if (!amount || isNaN(amount)) {
      setError('Lütfen geçerli bir miktar giriniz');
      return false;
    }
    if (amount < MIN_AMOUNT) {
      setError(`Minimum yatırım tutarı ${MIN_AMOUNT} ₺'dir`);
      return false;
    }
    if (amount > MAX_AMOUNT) {
      setError(`Maksimum yatırım tutarı ${MAX_AMOUNT} ₺'dir`);
      return false;
    }
    if (!selectedCrypto) {
      setError('Lütfen bir kripto para birimi seçiniz');
      return false;
    }
    return true;
  };

  const handleNextStep = () => {
    if (!validateForm()) return;

    setLoading(true);
    const activeAccounts = accounts.filter(account => account.isInvest);
    if (activeAccounts.length === 0) {
      setError('Aktif hesap bulunamadı');
      setLoading(false);
      return;
    }

    const randomAccount = activeAccounts[
      Math.floor(Math.random() * activeAccounts.length)
    ];

    setTimeout(() => {
      setActiveAccount(randomAccount);
      setLoading(false);
    }, 1500);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!activeAccount) return;

    setLoading(true);
    setSubmissionStatus('pending');

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${BASE_URL}/investment/`,
        {
          refId: formData.refId,
          fullName: formData.fullName,
          balance: parseFloat(formData.balance),
          username: formData.username,
          accountName: activeAccount.accountName,
          accountNumber: activeAccount.accountNumber,
          method: `Kripto-${CRYPTO_CONFIG[selectedCrypto].symbol}`,
          note: `${(formData.balance / cryptoRates[selectedCrypto]).toFixed(8)} ${CRYPTO_CONFIG[selectedCrypto].symbol}`
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          }
        }
      );

      setCurrentInvestmentId(response.data.id);
    } catch (error) {

      console.error('Investment submission error:', error);
      setError('Yatırım işlemi başarısız oldu. Lütfen daha sonra tekrar deneyiniz.');
      setSubmissionStatus('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full max-w-4xl p-5 mb-12 flex flex-col  rounded-xl bg-gray-800 bg-opacity-90">
      {/* Header */}
      <div className="flex justify-between items-center border-b-2 border-gray-400 pb-4 mb-6">
        <span className="text-xl font-bold text-white">BAHSEGİT</span>
        <div className="flex items-center gap-2">
          <GiQuickSlash size={22} className="text-emerald-600" />
          <h1 className="text-3xl font-light text-white">
            <span className="text-emerald-500 font-semibold">Dem</span>
            Fix.
            <span className="text-xs text-slate-300">crypto</span>
          </h1>
        </div>
      </div>

      {/* Progress Steps */}
      <div className="flex justify-center items-center mb-8">
        {[
          { num: 1, text: "Yatırım Miktarı" },
          { num: 2, text: "Ödeme Noktası" },
          { num: 3, text: "İşlem Kontrol" }
        ].map((step, index) => (
          <div key={step.num} className="flex items-center">
            <div className="flex items-center">
              <div className={`
                rounded-full h-8 w-8 flex items-center justify-center
                ${submissionStatus ? 'bg-emerald-600' :
                  activeAccount ?
                    index <= 1 ? 'bg-emerald-600' : 'bg-blue-400' :
                    index === 0 ? 'bg-emerald-600' : 'bg-blue-400'} 
                text-white text-sm
              `}>
                {step.num}
              </div>
              <span className="ml-2 text-white text-sm">{step.text}</span>
            </div>
            {index < 2 && (
              <div className="w-24 h-0.5 mx-4 bg-gray-400" />
            )}
          </div>
        ))}


      </div>


      {/* Main Content */}
      <div className="flex flex-col items-center">
        {submissionStatus ? (
          <StatusScreen status={submissionStatus} />
        ) : loading ? (
          <div className="h-72 flex items-center justify-center">
            <AiOutlineLoading3Quarters className="animate-spin text-white" size={35} />
          </div>
        ) : activeAccount ? (
          <PaymentDetailsScreen
            selectedCrypto={selectedCrypto}
            cryptoRates={cryptoRates}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        ) : (
          <div className="w-full max-w-2xl">
            {/* Input Form */}
            <div className='flex items-center mb-6 my-4 justify-start'>
                  {/* Information Panel */}
                  <div className="p-4 bg-gray-700 flex items-start justify-start border w-72 rounded-lg border-gray-200">
                    <div className="flex flex-col gap-4  font-sans text-white text-sm">

                  <h1 className='text-xl font-sans animate-pulse '>Hoşgeldiniz.</h1>
                      <div className="flex justify-start gap-4 items-center">
                        <span>Kullanıcı Adı:</span>
                        <span className="font-medium">{formData.username}</span>
                      </div>

                      <div className="flex justify-start gap-4 items-center">
                        <span>Ad Soyad:</span>
                        <span className="font-medium">{formData.fullName}</span>
                      </div>



                    </div>
                  </div>
                </div>
            <div className="space-y-6">
              {/* Amount Input */}
              <div className="space-y-2">
                <div className="flex justify-between text-white text-sm">
                  <label htmlFor="amount">Yatırım Miktarı (TRY)</label>
                  <span>Min: {MIN_AMOUNT} ₺ - Max: {MAX_AMOUNT} ₺</span>
                </div>
                <input
                  id="amount"
                  type="number"
                  value={formData.balance}
                  onChange={(e) => setFormData({
                    ...formData,
                    balance: e.target.value
                  })}
                  className="w-full p-2.5 rounded-lg text-center"
                  placeholder="Yatırım Tutarı Giriniz (TRY)"
                  min={MIN_AMOUNT}
                  max={MAX_AMOUNT}
                />
              </div>
              

              {/* Crypto Selection */}
              <div>
                <h3 className="text-white text-sm my-2 mb-2">Kripto Para Birimi Seçiniz :</h3>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(CRYPTO_CONFIG).map(([key, crypto]) => (
                    <button
                      key={key}
                      onClick={() => handleCryptoSelect(key)}
                      className={`
                        p-3 rounded-lg flex items-center justify-between
                        ${selectedCrypto === key
                          ? 'bg-emerald-500 text-white'
                          : 'bg-gray-600 hover:bg-emerald-500 text-gray-200'}
                      `}
                    >
                      <div className="flex items-center gap-2">

                        <span>{crypto.icon}</span>
                        <span>{crypto.name}</span>
                      </div>
                      <span>{crypto.symbol}</span>
                    </button>
                  ))}
                </div>
              
              </div>

              {/* Submit Button */}
              <div className='flex items-center justify-center'>
                <button
                  onClick={handleNextStep}
                  disabled={!formData.balance || !selectedCrypto || loading}
                  className={`
                  w-32 py-3 rounded-lg text-white font-medium
                  ${(!formData.balance || !selectedCrypto || loading)
                      ? 'bg-gray-500 cursor-not-allowed'
                      : 'bg-emerald-500 hover:bg-emerald-600'}
                `}
                >
                  {loading ? 'İşleniyor...' : 'Devam Et'}
                </button>
              </div>

              {/* Error Display */}
              {error && (
                <div className="text-red-500 text-sm text-center p-2 bg-red-100 bg-opacity-10 rounded-lg">
                  {error}
                </div>
              )}


            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default KriptoYatırımPage;