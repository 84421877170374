import React, { useState } from 'react';
import axios from 'axios';

const KasaSubtractModal = ({ show, onClose, refId, refName, currentBalance, onSuccess }) => {
    const [amount, setAmount] = useState('');
    const [note, setNote] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/refs/${refId}/subtract_balance`, {
                amount: parseFloat(amount),
                note: note
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });

            onSuccess();
            onClose();
        } catch (error) {
            setError(error.response?.data?.detail || 'An error occurred');
        }
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
                <h2 className="text-lg font-semibold mb-4">Bakiye Çıkar - {refName}</h2>
                <p className="text-sm text-gray-600 mb-4">Mevcut Bakiye: {currentBalance} ₺</p>

                {error && (
                    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4">
                        {error}
                    </div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Miktar (₺)
                        </label>
                        <input
                            type="number"
                            step="0.01"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className="w-full p-2 border rounded focus:ring-emerald-500 focus:border-emerald-500"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Not
                        </label>
                        <textarea
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            className="w-full p-2 border rounded focus:ring-emerald-500 focus:border-emerald-500"
                            rows="3"
                            required
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
                        >
                            İptal
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 text-sm bg-yellow-500 text-white rounded hover:bg-yellow-600"
                        >
                            Çıkar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default KasaSubtractModal;