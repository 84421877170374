import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DateInput from '../components/DateInput';

const BASE_URL = process.env.REACT_APP_API_URL;

function Siteler() {
  const [refsLogsData, setRefsLogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchRefsLogsData = async () => {
    const token = localStorage.getItem('token');

    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/refs/with_investments/count`, {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      setRefsLogsData(response.data);
    } catch (error) {
      setError('veri hata');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRefsLogsData();
  }, []);

  const formatTurkishLira = (amount) => {
    const numAmount = Number(amount) || 0;
    return new Intl.NumberFormat('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numAmount);
  };

  return (
    <div className='top-0 bottom-0 h-screen right-0 left-0'>
    <div className="overflow-x-auto w-full">
      <div className="my-4 p-3 border-2">
        <DateInput />
      </div>

      {/* Responsive Table */}
      <div className="overflow-x-auto">
        <table
          style={{
            boxShadow: '4px 6px 8px gray',
          }}
          className="w-full container mx-auto border-collapse shadow-md rounded-lg overflow-hidden"
        >
          <thead className="bg-gray-800 text-white border-b-2 border-gray-200">
            <tr className="font-sans">
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                Site
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                Yatırım Miktarı
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                Yatırım Sayısı
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                Çekim Miktarı
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                Çekim Sayısı
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y">
            {refsLogsData.length > 0 ? (
              refsLogsData.slice(0).map((refsLog) => (
                <tr
                  key={refsLog.id}
                  className="bg-emerald-200 font-sans text-xs transition-colors duration-200"
                >
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                    {refsLog.refName || 'N/A'}
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                    {formatTurkishLira(refsLog.total_investments)} ₺
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                    {refsLog.investment_count} adet
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                    {formatTurkishLira(refsLog.total_withdrawals)} ₺
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                    {refsLog.withdrawal_count} adet
                  </td>
                </tr>
              ))
            ) : (
              <tr className='w-full  '>
                <td colSpan="5" className="text-center px-4 py-3">
                  <h2 className="text-gray-500 text-sm my-3 font-medium">Veri Bulunamadı.</h2>
                </td>
              </tr>
            )}
          </tbody>

        </table>
      </div>

      {/* New Summary Section */}
      <div className="border-2 bg-slate-200 container mx-auto flex gap-4 flex-wrap flex-col cursor-pointer rounded-md mt-4 p-6">
        <div className="flex flex-col md:flex-row justify-center gap-6">
          {/* Yatırım Sayısı ve Çekim Sayısı */}
          <div className="w-full md:w-80 font-sans bg-emerald-200 p-6 rounded-lg">
            <h3 className="text-lg text-center font-bold text-gray-700 mb-3">Yatırım Sayısı</h3>
            <div className="text-xl text-center font-bold text-gray-600">
              <span className="p-2 bg-white rounded-md">
                {refsLogsData.slice(0).reduce((total, log) => total + Number(log.investment_count), 0)} adet
              </span>
            </div>
          </div>

          <div className="w-full md:w-80 font-sans bg-red-200 p-6 rounded-lg">
            <h3 className="text-lg text-center font-bold text-gray-700 mb-3">Çekim Sayısı</h3>
            <div className="text-xl text-center font-bold text-gray-600">
              <span className="p-2 bg-white rounded-md">
                {refsLogsData.slice(0).reduce((total, log) => total + Number(log.withdrawal_count), 0)} adet
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center gap-6">
          {/* Toplam Yatırım ve Toplam Çekim */}
          <div className="w-full md:w-80 font-sans bg-emerald-200 p-6 rounded-lg">
            <h3 className="text-lg text-center font-bold text-gray-700 mb-3">Toplam Yatırım</h3>
            <div className="text-xl text-center font-bold text-gray-600">
              <span className="p-2 bg-white rounded-md">
                {formatTurkishLira(
                  refsLogsData.slice(0).reduce((total, log) => total + Number(log.total_investments), 0)
                )} ₺
              </span>
            </div>
          </div>

          <div className="w-full md:w-80 font-sans bg-red-200 p-6 rounded-lg">
            <h3 className="text-lg text-center font-bold text-gray-700 mb-3">Toplam Çekim</h3>
            <div className="text-xl text-center font-bold text-gray-600">
              <span className="p-2 bg-white rounded-md">
                {formatTurkishLira(
                  refsLogsData.slice(0).reduce((total, log) => total + Number(log.total_withdrawals), 0)
                )} ₺
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Siteler;
