import React from 'react'
import ParavanForm from '../components/ParavanCekim'

function paravanCekim() {
  return (
    <section className="h-screen login-page relative">
            <article className="container h-full mx-auto flex  items-center justify-center">
                     <ParavanForm />
            </article>
    </section>
  )
}

export default paravanCekim
