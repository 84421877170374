import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;

const WithdrawalForm = ({ show, onClose, title }) => {
  const [refs, setRefs] = useState([]);
  const [withdrawal, setWithdrawal] = useState([]);
  const [formData, setFormData] = useState({
    refId: '',
    fullName: '',
    balance: '',
    username: '',
    targetAccountNumber: '',
    method: '',
    note: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchRefs = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${BASE_URL}/refs/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setRefs(response.data.data);
      } catch (error) {
        console.error("Error fetching refs:", error);
        setError(error.response?.data?.detail || "Failed to load references");
      }
    };

    if (show) {
      fetchRefs();
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let processedValue = value;

    if (name === 'balance') {
      // Remove any non-numeric characters except decimal point
      const numericValue = value.replace(/[^\d.]/g, '');
      // Ensure only one decimal point
      const parts = numericValue.split('.');
      if (parts.length > 2) {
        processedValue = parts[0] + '.' + parts.slice(1).join('');
      } else {
        processedValue = numericValue;
      }
      // Convert to number if valid
      if (processedValue) {
        processedValue = parseFloat(processedValue);
      }
    }

    setFormData(prev => ({
      ...prev,
      [name]: processedValue
    }));
  };

  const validateForm = () => {
    const errors = [];

    if (!formData.refId || formData.refId.trim() === '')
      errors.push("Referans seçimi zorunludur");
    if (!formData.fullName || formData.fullName.trim() === '')
      errors.push("Ad Soyad alanı zorunludur");
    if (!formData.balance || isNaN(formData.balance) || formData.balance <= 0)
      errors.push("Geçerli bir miktar giriniz");
    if (!formData.username || formData.username.trim() === '')
      errors.push("Kullanıcı adı zorunludur");
    if (!formData.targetAccountNumber || formData.targetAccountNumber.trim() === '')
      errors.push("Hedef hesap numarası zorunludur");
    if (!formData.method || formData.method.trim() === '')
      errors.push("Ödeme yöntemi zorunludur");

    return errors;
  };

  const resetForm = () => {
    setFormData({
      refId: '',
      fullName: '',
      balance: '',
      username: '',
      targetAccountNumber: '',
      method: '',
      note: ''
    });
    setError('');
    setSuccessMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setError(validationErrors.join(', '));
      return;
    }

    setIsLoading(true);
    setError('');
    setSuccessMessage('');

    const token = localStorage.getItem('token');

    // Build URL with query parameters
    const params = new URLSearchParams();
    Object.entries({
      refId: formData.refId.trim(),
      fullName: formData.fullName.trim(),
      balance: parseFloat(formData.balance),
      username: formData.username.trim(),
      targetAccountNumber: formData.targetAccountNumber.trim(),
      method: formData.method.trim(),
      note: formData.note.trim() || ''
    }).forEach(([key, value]) => {
      params.append(key, value);
    });

    try {
      const response = await axios({
        method: 'post',
        url: `${BASE_URL}/withdrawal/?${params.toString()}`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      setSuccessMessage('Çekim talebi başarıyla oluşturuldu');
      setWithdrawal([...withdrawal, response.data]);
      setShowModal(true);
      resetForm();
    } catch (error) {
      console.error('Error details:', {
        error: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers
      });

      let errorMessage = 'Çekim talebi oluşturulurken bir hata oluştu.';

      if (error.response?.data?.detail) {
        errorMessage = error.response.data.detail;
      }

      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    onClose();
    window.location.reload();
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl">
        <div className="bg-emerald-600 p-4 rounded-t-lg">
          <h1 className="text-white font-bold text-xl text-center">{title || 'Yeni Çekim Talebi'}</h1>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4 mx-6">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        {successMessage && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4 mx-6">
            <span className="block sm:inline">{successMessage}</span>
          </div>
        )}

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-4">
              <div>
                <label className="block text-xs font-bold mb-2">Referans*</label>
                <select 
                  name="refId"
                  value={formData.refId}
                  onChange={handleChange}
                  className="w-full p-1 text-sm border rounded-md"
                  required
                >
                  <option value="">Seçiniz</option>
                  {refs.map((ref) => (
                    <option key={ref.refId} value={ref.refId}>
                      {ref.refName}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-xs font-bold mb-2">Ad Soyad*</label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="w-full p-1 text-sm border rounded-md"
                  placeholder="Ad Soyad"
                  required
                  maxLength={100}
                />
              </div>

              <div>
                <label className="block text-xs font-bold mb-2">Miktar*</label>
                <input
                  type="number"
                  name="balance"
                  value={formData.balance}
                  onChange={handleChange}
                  className="w-full p-1 text-sm border rounded-md"
                  placeholder="0.00"
                  step="0.01"
                  min="0.01"
                  required
                />
              </div>

              <div>
                <label className="block text-xs font-bold mb-2">Kullanıcı Adı*</label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full p-1 text-sm border rounded-md"
                  placeholder="Kullanıcı Adı"
                  required
                  maxLength={50}
                />
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-xs font-bold mb-2">Hedef Hesap No*</label>
                <input
                  type="text"
                  name="targetAccountNumber"
                  value={formData.targetAccountNumber}
                  onChange={handleChange}
                  className="w-full p-1 text-sm border rounded-md"
                  placeholder="Hedef Hesap No"
                  required
                  maxLength={24}
                />
              </div>

              <div>
                <label className="block text-xs font-bold mb-2">Ödeme Yöntemi*</label>
                <select
                  name="method"
                  value={formData.method}
                  onChange={handleChange}
                  className="w-full p-1 text-sm border rounded-md"
                  required
                >
                  <option value="">Seçiniz</option>
                  <option value="Havale">Havale</option>
                  <option value="EFT">EFT</option>
                  <option value="FAST">FAST</option>
                  <option value="SWIFT">SWIFT</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-bold mb-2">Not</label>
                <textarea
                  name="note"
                  value={formData.note}
                  onChange={handleChange}
                  className="w-full p-1 text-sm border rounded-md"
                  rows="3"
                  placeholder="Notunuz"
                  maxLength={500}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-4 pt-4">
            <button
              type="submit"
              disabled={isLoading}
              className="bg-emerald-500 text-white px-3 py-1.5 rounded-md hover:bg-emerald-600 disabled:opacity-50 text-xs"
            >
              {isLoading ? 'Çekim Talebi Gönderiliyor...' : 'Çekim Talebi Gönder'}
            </button>

            <button
              type="button"
              onClick={onClose}
              disabled={isLoading}
              className="bg-red-500 text-white px-3 py-1.5 rounded-md hover:bg-red-600 disabled:opacity-50 text-xs"
            >
              Kapat
            </button>
          </div>
        </form>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Çekim Talebi Başarıyla Oluşturuldu</h2>
            <span className='flex items-center justify-center'>
              <button
                onClick={handleCloseModal}
                className="bg-emerald-500 text-white px-3 text-xs py-1.5 rounded-md hover:bg-emerald-600"
              >
                Kapat
              </button>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithdrawalForm;