import React from 'react';
import '../css/confirmBox.css'

 const PopBox = ({ show, onClose, title }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-box">
        <h2 className='my-2'>{title}</h2>
        <hr />
        <div className='flex flex-row items-center my-2 justify-center gap-4 '>
    
          <button type='button' className='confirm-box-button p-1.5 text-xs rounded-md bg-emerald-500 text-white '
            onClick={onClose}>Kapat</button>
        </div>
      </div>
    </div>
  );
};

export default PopBox;