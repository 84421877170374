import React from 'react'

function NotFound() {
  return (
    <div className='flex items-start justify-center p-5 flex-col gap-2'>
      <h2 className='text-3xl xl:text-4xl tracking-wide font-extrabold'>404 Sayfa Bulunamadı!</h2>
      <h2 className='text-xl xl:text2-xl font-semibold'>Girmiş olduğunuz link hatalı, lütfen başka bir link üzerinden giriş deneyiniz.</h2>
    </div>
  )
}

export default NotFound