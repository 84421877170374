import React from 'react';
import '../css/confirmBox.css'

 const ConfirmBox = ({ show, onClose, onSuccess, title}) => {
  

  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-box">
        <h2 className='py-3'>{title}</h2>
        <hr />
        <div className='flex flex-row my-2 items-center justify-center gap-4 '>
          <button type='button' className='mt-2 w-12 p-1.5 z-200 border-none cursor-pointer text-white rounded-md text-xs bg-emerald-500 hover:bg-emerald-600 duration-700 ease-in-out transition-all'
            onClick={onSuccess}>Evet</button>

          <button type='button' className='bg-red-500 w-12 mt-2 p-1.5 rounded-md z-200 text-white border-none cursor-pointer text-xs hover:bg-red-600 duration-700 ease-in-out transition-all'
            onClick={onClose}>Kapat</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBox;