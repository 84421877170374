import React from 'react'
import KriptoYatırım from '../components/KriptoYatırım';
import '../index.css'


function KriptoYatırımPage() {
  return (
    <section className="h-screen login-page relative">
            <article className="container h-full  mx-auto flex  items-center justify-center">
                     <KriptoYatırım />
            </article>
    </section>
  )
}

export default KriptoYatırımPage
