import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProgressBox from './ConfirmBox';
import ConfirmBox from './ConfirmBox';


const BASE_URL = process.env.REACT_APP_API_URL;
const ROWS_PER_PAGE = 30;


const InvestmentTable = ({ filters, autoUpdate }) => {

    const [investments, setInvestments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');
    const [refs, setRefs] = useState({});
    const [users, setUsers] = useState({});
    const [updateLoading, setUpdateLoading] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [selectedInvestmentId, setSelectedInvestmentId] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    
    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filteredInvestments, setFilteredInvestments] = useState([]);


    const applyFilters = (investments) => {
        const filtered = investments.filter(investment => {
            // 1. Ref Filter
            if (filters.refIds && filters.refIds.length > 0) {
                if (!filters.refIds.includes(investment.refId)) {
                    return false;
                }
            }
    
            // 2. Account Number Filter
            if (filters.accountNumbers && filters.accountNumbers.length > 0) {
                if (!filters.accountNumbers.includes(investment.accountNumber)) {
                    return false;
                }
            }
    
            // 3. Amount Range Filter
            if (filters.minAmount && !isNaN(filters.minAmount)) {
                if (parseFloat(investment.balance) < parseFloat(filters.minAmount)) {
                    return false;
                }
            }
    
            if (filters.maxAmount && !isNaN(filters.maxAmount)) {
                if (parseFloat(investment.balance) > parseFloat(filters.maxAmount)) {
                    return false;
                }
            }
    
            // 4. Date Range Filter
            if (filters.startDate || filters.endDate) {
                const investmentDate = new Date(investment.transactionDate);
    
                if (filters.startDate) {
                    const startDate = new Date(filters.startDate);
                    startDate.setHours(0, 0, 0, 0);
                    if (investmentDate < startDate) {
                        return false;
                    }
                }
    
                if (filters.endDate) {
                    const endDate = new Date(filters.endDate);
                    endDate.setHours(23, 59, 59, 999);
                    if (investmentDate > endDate) {
                        return false;
                    }
                }
            }
    
            // 5. Search Term Filter
            if (searchTerm) {
                const searchLower = searchTerm.toLowerCase();
                const matchesSearch = (
                    investment.id.toString().includes(searchLower) ||
                    (refs[investment.refId] || '').toLowerCase().includes(searchLower) ||
                    investment.fullName.toLowerCase().includes(searchLower) ||
                    investment.username.toLowerCase().includes(searchLower) ||
                    investment.accountNumber.includes(searchLower)
                );
    
                if (!matchesSearch) {
                    return false;
                }
            }
    
            // 6. Status Filter
            if (statusFilter !== 'all') {
                if (investment.investmentStatus !== statusFilter) {
                    return false;
                }
            }
    
            return true;
        });
        setFilteredInvestments(filtered);
        setTotalPages(Math.ceil(filtered.length / ROWS_PER_PAGE));
        setCurrentPage(1); // Reset to first page when filters change
        return filtered;
    };
    const getCurrentPageInvestments = () => {
        const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
        const endIndex = startIndex + ROWS_PER_PAGE;
        return filteredInvestments.slice(startIndex, endIndex);
    };
    const fetchInvestments = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/investment/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            let sortedInvestments = response.data.data.sort((a, b) =>
                new Date(b.transactionDate) - new Date(a.transactionDate)
            );
            sortedInvestments = applyFilters(sortedInvestments);
            setInvestments(sortedInvestments);
            setError(null);
        } catch (error) {
            console.error("Error fetching investments:", error);
            setError("Yatırımlar yüklenirken bir hata oluştu. Lütfen tekrar deneyin.");
        } finally {
            setLoading(false);
        }
    };
    const fetchRefs = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/refs/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const refsMap = {};
            response.data.data.forEach(ref => {
                refsMap[ref.refId] = ref.refName;
            });
            setRefs(refsMap);
        } catch (error) {
            console.error("Error fetching refs:", error);
        }
    };
    const fetchUsers = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/users`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const usersMap = {};
            response.data.data.forEach(user => {
                usersMap[user.id] = user.username;
            });
            setUsers(usersMap);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };
    const fetchCurrentUser = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/users/me`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setCurrentUser(response.data);
        } catch (error) {
            console.error("Error fetching current user:", error);
        }
    };
    useEffect(() => {
        fetchInvestments();
        fetchRefs();
        fetchUsers();
        fetchCurrentUser();
        
        let interval;
        if (autoUpdate) {
            interval = setInterval(() => {
                fetchInvestments();
            }, 60000);
        }
    
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [filters, autoUpdate, searchTerm, statusFilter]);
    async function handleTakeOwnership(investmentId) {
        const token = localStorage.getItem('token');
        try {
            setUpdateLoading(investmentId);
            await axios.post(`${BASE_URL}/investment/${investmentId}/take-ownership`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            await fetchInvestments();
            setShowModal(false);
        } catch (error) {
            console.error("Error taking ownership:", error);
            setError("Yatırım sahiplenirken bir hata oluştu.");
        } finally {
            setUpdateLoading(null);
        }
    }
    const handleStatusUpdate = async (investmentId, newStatus) => {
        const token = localStorage.getItem('token');
        setUpdateLoading(investmentId);
        try {
            const investment = investments.find(inv => inv.id === investmentId);
            if (!investment.userID) {
                await handleTakeOwnership(investmentId);
            }
            await axios.patch(`${BASE_URL}/investment/${investmentId}`, {
                investmentStatus: newStatus,
                note: newStatus === 'Onaylandı'
                    ? 'Yatırım onaylandı'
                    : newStatus === 'İptal'
                        ? 'Yatırım iptal edildi'
                        : 'Yatırım beklemede',
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            await fetchInvestments();
        } catch (error) {
            setError("Bu Yatırımı Onaylamaya Yetkiniz Yok.");
        } finally {
            setUpdateLoading(null);
        }
    };
    const handleCloseShowModal = () => {
        setShowModal(false);
        setSelectedInvestmentId(null);
    };
    const handleCloseShowModal2 = () => {
        setShowModal2(false);
        setSelectedInvestmentId(null);
    };
    const handleCloseShowModal3 = () => {
        setShowModal3(false);
        setSelectedInvestmentId(null);
    };
    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 'Onaylandı':
                return 'bg-emerald-600 text-slate-200';
            case 'İptal':
                return 'bg-red-600 text-slate-200';
            case 'Beklemede':
            default:
                return 'bg-yellow-600 text-slate-100';
        }
    };
    const getStatusTableClass = (status) => {
        switch (status) {
            case 'Onaylandı':
                return 'bg-emerald-200 text-white';
            case 'İptal':
                return 'bg-red-200 text-white';
            case 'Beklemede':
                return 'bg-amber-100 text-white';
            default:
                return 'bg-gray-100 text-slate-100';
        }
    };
    const Pagination = () => {
        const pageNumbers = [];
        const maxVisiblePages = 5;
        
        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
        
        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        return (
            <div className="flex items-center justify-center space-x-2 my-4">
                <button
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1}
                    className="px-3 py-1 bg-gray-200 rounded-md disabled:opacity-50"
                >
                    &#171;
                </button>
                <button
                    onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                    disabled={currentPage === 1}
                    className="px-3 py-1 bg-gray-200 rounded-md disabled:opacity-50"
                >
                    &#8249;
                </button>
                {pageNumbers.map(number => (
                    <button
                        key={number}
                        onClick={() => setCurrentPage(number)}
                        className={`px-3 py-1 rounded-md ${
                            currentPage === number 
                                ? 'bg-emerald-500 text-white' 
                                : 'bg-gray-200'
                        }`}
                    >
                        {number}
                    </button>
                ))}
                <button
                    onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                    disabled={currentPage === totalPages}
                    className="px-3 py-1 bg-gray-200 rounded-md disabled:opacity-50"
                >
                    &#8250;
                </button>
                <button
                    onClick={() => setCurrentPage(totalPages)}
                    disabled={currentPage === totalPages}
                    className="px-3 py-1 bg-gray-200 rounded-md disabled:opacity-50"
                >
                    &#187;
                </button>
                <span className="text-sm text-gray-600">
                    Sayfa {currentPage} / {totalPages}
                </span>
            </div>
        );
    };
    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
            </div>
        );
    }
    return (
        <div className="container-fluid mx-auto p-3">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="p-4 bg-gray-300">
                    <div className="flex items-center justify-between space-x-4">
                        <div className="flex-1">
                            <input
                                type="search"
                                placeholder="ID, Site, Kullanıcı Adı veya Hesap No ile ara..."
                                className="w-full px-4 py-2 rounded-lg border text-xs focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <select
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            className="px-4 py-2 border rounded-lg text-xs focus:outline-none focus:ring-2 focus:ring-emerald-500"
                        >
                            <option value="all">Tüm Durumlar</option>
                            <option value="Beklemede">Bekleyenler</option>
                            <option value="İşlem">İşlemdekiler</option>
                            <option value="Onaylandı">Onaylananlar</option>
                            <option value="İptal">Reddedilenler</option>
                        </select>
                        <button
                            onClick={fetchInvestments}
                            className="px-4 py-2 bg-emerald-500 text-white rounded-lg text-xs hover:bg-emerald-600 transition-colors"
                        >
                            Yenile
                        </button>
                    </div>
                </div>
                {error && (
                    <div className="p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                        <p className="font-medium">Hata!</p>
                        <p>{error}</p>
</div>
                )}
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-800">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">ID</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Site</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">RefID</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Adı Soyadı</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Miktar</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Kullanıcı Adı</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Yöntem</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Hesap Adı</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Hesap No</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">İşlem Tarihi</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">Sonuç Tarihi</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">İşlemi Yapan</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">Durum</th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">İşlemler</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {getCurrentPageInvestments().map((investment) => (
                                <tr key={investment.id} className={`text-xs ${getStatusTableClass(investment.investmentStatus)}`}>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{investment.id}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{refs[investment.refId] || '-'}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{investment.id}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{investment.fullName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        <span className={`p-1 border ${investment.balance <= 4999 ? 'bg-yellow-600' : 'bg-emerald-600'} rounded-md text-white`}>
                                            {investment.balance.toLocaleString()} ₺
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{investment.username}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{investment.method}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">{investment.accountName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">TR {investment.accountNumber}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs italic text-gray-500">
                                        {new Date(investment.transactionDate).toLocaleString("tr-TR")}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs italic text-gray-500">
                                        {investment.resultDate && new Date(investment.resultDate).toLocaleString("tr-TR")}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-white">
                                        <span className={`p-1 rounded-lg ${users[investment.userID] ? 'bg-red-400' : 'bg-red-600'}`}>
                                            {users[investment.userID] || 'Seçilmedi'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-center">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeClass(investment.investmentStatus)}`}>
                                            {investment.investmentStatus}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-center">
                                        <div className="flex items-center justify-center space-x-2">
                                            {investment.investmentStatus === 'Beklemede' && (
                                                <>
                                                    {!investment.userID && (
                                                        <button
                                                            onClick={() => {
                                                                setSelectedInvestmentId(investment.id);
                                                                setShowModal(true);
                                                            }}
                                                            disabled={updateLoading === investment.id}
                                                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-xs"
                                                        >
                                                            {updateLoading === investment.id ? 'İşleniyor...' : 'İşlemi Al'}
                                                        </button>
                                                    )}
                                                    {investment.userID && (
                                                        <div className='flex flex-row items-center justify-center gap-2'>
                                                            {investment.userID === currentUser?.id ? (
                                                                <>
                                                                    <button
                                                                        onClick={() => {
                                                                            setSelectedInvestmentId(investment.id);
                                                                            setShowModal2(true);
                                                                        }}
                                                                        disabled={updateLoading === investment.id}
                                                                        className="px-3 py-1 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition-colors text-xs"
                                                                    >
                                                                        {updateLoading === investment.id ? 'Onaylanıyor...' : 'Onayla'}
                                                                    </button>
                                                                    <button
                                                                        onClick={() => {
                                                                            setSelectedInvestmentId(investment.id);
                                                                            setShowModal3(true);
                                                                        }}
                                                                        disabled={updateLoading === investment.id}
                                                                        className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors text-xs"
                                                                    >
                                                                        {updateLoading === investment.id ? 'Reddediliyor...' : 'Reddet'}
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <button
                                                                    onClick={() => {
                                                                        setSelectedInvestmentId(investment.id);
                                                                        handleTakeOwnership(investment.id);
                                                                    }}
                                                                    disabled={updateLoading}
                                                                    className="px-3 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors text-xs"
                                                                >
                                                                    {updateLoading ? 'İşlem Alınıyor...' : 'İşlemi Devral'}
                                                                </button>
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                {filteredInvestments.length > 0 && <Pagination />}
                {filteredInvestments.length === 0 && (
                    <div className="text-center py-6 text-gray-500">
                        Yatırım Bulunamadı.
                    </div>
                )}
                {/* Take Ownership Modal */}
                {showModal && (
                    <ProgressBox
                        title="İşlemi almak istiyor musun?"
                        show={showModal}
                        onSuccess={() => handleTakeOwnership(selectedInvestmentId)}
                        onClose={handleCloseShowModal}
                    />
                )}
                {/* Approve Modal */}
                {showModal2 && (
                    <ConfirmBox
                        title="İşlemi onaylamak istiyor musun?"
                        show={showModal2}
                        onSuccess={() => {
                            handleStatusUpdate(selectedInvestmentId, 'Onaylandı');
                            setShowModal2(false);
                        }}
                        onClose={handleCloseShowModal2}
                    />
                )}
                {/* Reject Modal */}
                {showModal3 && (
                    <ConfirmBox
                        title="İşlemi reddetmek istiyor musun?"
                        show={showModal3}
                        onSuccess={() => {
                            handleStatusUpdate(selectedInvestmentId, 'İptal');
                            setShowModal3(false);
                        }}
                        onClose={handleCloseShowModal3}
                    />
                )}
            </div>
        </div>
    );
};

export default InvestmentTable;
