import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';

const UserMenu = ({ userName }) => {

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };


/* 
  const handleProfile = () => {
    navigate('/profile');
  }

*/


  return (
    <div className="relative flex items-center text-white cursor-pointer gap-2 justify-center">
      <h1 className='text-xs hidden md:flex items-center justify-center'>{userName}</h1>
      <FaUser className='text-lg' onClick={toggleMenu} />
      {isOpen && (
        <div style={{
          zIndex: '9999',
        }} className="absolute right-0 mt-20 bg-gray-600 text-xs text-white font-semibold rounded shadow-lg">
          <ul className="p-1.5 px-1">
            <li 
            className="px-2 py-1 hidden text-xs hover:bg-gray-700 cursor-pointer"
            >Profile</li>

            <li style={{
              fontSize: '10px'
            }}
              className="px-0 py-1  hover:bg-gray-700 cursor-pointer"
              onClick={handleLogout}
            >
              Çıkış Yap
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
