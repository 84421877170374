import React, { useState, useEffect } from 'react';
import { IoIosClose } from "react-icons/io";
import { FaUser, FaMoneyBillWave } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import axios from 'axios';

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;

const TransferBox = ({ show, onClose }) => {

    
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTermSender, setSearchTermSender] = useState('');
    const [searchTermRecipient, setSearchTermRecipient] = useState('');
    const [selectedSenderAccount, setSelectedSenderAccount] = useState(null);
    const [selectedRecipientAccount, setSelectedRecipientAccount] = useState(null);
    const [amount, setAmount] = useState('');
    const [note, setNote] = useState('');
    const [transferSuccess, setTransferSuccess] = useState(false);
    const [showSenderList, setShowSenderList] = useState(false);
    const [showRecipientList, setShowRecipientList] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/bank/accounts`);
            const sortedUsers = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setUsers(sortedUsers);
        } catch (error) {
            console.error("Error fetching accounts:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filterUsers = (users, searchTerm) => {
        return users.filter(user =>
            user.accountNumber.includes(searchTerm) ||
            user.accountName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const handleTransfer = async () => {
        if (!selectedSenderAccount || !selectedRecipientAccount || !amount) {
            alert("Please select both accounts and enter an amount.");
            return;
        }

        if (parseFloat(amount) > selectedSenderAccount.accountBalance) {
            alert("Insufficient balance in sender's account!");
            return;
        }

        try {
            const transfer = {
                sender_account_id: selectedSenderAccount.accountId,
                receiver_account_id: selectedRecipientAccount.accountId,
                amount: parseInt(amount),
                note: note
            };

            const token = localStorage.getItem('token');

            await axios.post(`${BASE_URL}/bank/transfer`, transfer, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            setTransferSuccess(true);
            fetchData();
            setSearchTermSender(null);
            setSearchTermRecipient(null);
            setTimeout(() => {
                setTransferSuccess(false);
                onClose();
                window.location.reload();
            }, 1000);

        } catch (error) {
            console.error("Transfer error:", error);
            alert("An error occurred. Please try again.");
        }
    };

    const handleAccountSelect = (user, isSender) => {
        if (isSender) {
            setSelectedSenderAccount(user);
            setSearchTermSender(`${user.accountName} (${user.accountNumber})`);
            setShowSenderList(false);
        } else {
            setSelectedRecipientAccount(user);
            setSearchTermRecipient(`${user.accountName} (${user.accountNumber})`);
            setShowRecipientList(false);
        }
    };

    const renderAccountList = (filteredUsers, isSender) => (
        <div className="absolute z-10 w-full bg-white shadow-lg rounded-md border border-gray-200 mt-1 max-h-48 overflow-y-auto">
            {loading ? (
                <div className="p-3 text-gray-500 text-sm">Loading...</div>
            ) : filteredUsers.length > 0 ? (
                filteredUsers.map(user => (
                    <div
                        key={user.id}
                        className="p-3 hover:bg-gray-50 cursor-pointer border-b last:border-b-0"
                        onClick={() => handleAccountSelect(user, isSender)}
                    >
                        <div className="text-sm font-medium text-gray-900">{user.accountName}</div>
                        <div className="text-xs text-gray-500">
                            {user.bankName} • {user.accountNumber}
                            <span className="ml-2 text-emerald-500">
                                Miktar: {user.accountBalance.toLocaleString()}₺
                            </span>
                        </div>
                    </div>
                ))
            ) : (
                <div className="p-3 text-sm text-gray-500">Hesap Bulunamadı.</div>
            )}
        </div>
    );

    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl overflow-hidden">
                {/* Header */}
                <div className="bg-yellow-600 px-6 py-4 flex items-center justify-between">
                    <div className="flex items-center space-x-2 text-white">
                        <FaMoneyBillTransfer className="text-xl" />
                        <h2 className="text-lg text-white font-medium">Hesaplar Arası Transfer</h2>
                    </div>
                    <button
                        onClick={onClose}
                        className="text-white hover:text-gray-200 transition-colors"
                    >
                        <IoIosClose className="text-2xl" />
                    </button>
                </div>

                {/* Content */}
                <div className="p-6 space-y-6">
                    {transferSuccess && (
                        <div className="bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded-md flex items-center justify-center">
                          Transfer Başarıyla Gerçekleşti.
                        </div>
                    )}

                    {/* Sender Account */}
                    <div className="space-y-2 relative">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <FaUser className="mr-2" />
                           Gönderen Hesap
                        </label>
                        <input
                            type="text"
                            placeholder="Hesap Sahibi veya IBAN numarası"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 text-sm"
                            value={searchTermSender || ''}
                            onChange={(e) => {
                                setSearchTermSender(e.target.value);
                                setShowSenderList(true);
                            }}
                            onFocus={() => setShowSenderList(true)}
                        />
                        {showSenderList && renderAccountList(filterUsers(users, searchTermSender), true)}
                    </div>

                    {/* Recipient Account */}
                    <div className="space-y-2 relative">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <FaUser className="mr-2" />
                            Alıcı Hesap
                        </label>
                        <input
                            type="text"
                            placeholder="Hesap Sahibi veya IBAN numarası"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 text-sm"
                            value={searchTermRecipient || ''}
                            onChange={(e) => {
                                setSearchTermRecipient(e.target.value);
                                setShowRecipientList(true);
                            }}
                            onFocus={() => setShowRecipientList(true)}
                        />
                        {showRecipientList && renderAccountList(filterUsers(users, searchTermRecipient), false)}
                    </div>

                    {/* Amount */}
                    <div className="space-y-2">
                        <label className="flex items-center text-sm font-medium text-gray-700">
                            <FaMoneyBillWave className="mr-2" />
                            Miktar
                        </label>
                        <input
                            type="number"
                            placeholder="5000₺"
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 text-sm"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </div>

                    {/* Note */}
                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            Not
                        </label>
                        <input
                            type="text"
                            placeholder="Lütfen not bildiriniz."
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500 text-sm"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </div>

                    {/* Footer */}
                    <div className="flex justify-end pt-4">
                        <button
                            onClick={handleTransfer}
                            className="px-4 py-1.5 text-sm bg-emerald-600 text-white rounded-md hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 transition-colors"
                        >
                            Transfer Et
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransferBox;