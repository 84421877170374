import React, { useState } from 'react';
import '../css/yekiyonetımıbox.css';

function RoleManagementTable() {

    const [selectedRole, setSelectedRole] = useState(null);
    const [openPermissions, setOpenPermissions] = useState({});

    const handleRoleChange = (event) => {
        const selectedRoleId = Number(event.target.value);
        const role = roles.find((role) => role.id === selectedRoleId);
        setSelectedRole(role);
        setOpenPermissions({});
    };

    const getFeatureStyle = (hasFeature) => ({
        color: hasFeature ? 'green' : 'red',
    });

    const togglePermissionBox = (permissionType) => {
        setOpenPermissions((prevState) => ({
            ...prevState,
            [permissionType]: !prevState[permissionType],
        }));
    };

    const roles = [

        {
            id: 1,
            name: 'Admin',
            description: 'Sistem üzerinde tam yetkiye sahiptir.',
            features: ['Anasayfa', 'Yatırım Talepleri', 'Çekim Talepleri', 'Hesap Yönetimi', 'Admin Yönetimi', 'Yetki Yönetimi', 'Rapor Yönetimi', 'Blacklist', 'Refs'],
            permissions: {
                'Anasayfa Yetkileri': ['Anasayfayı Görebilir', 'Yatırım Toplamını Görebilir', 'Çekim Toplamını Görebilir'],
                'Yatırım Yetkileri': ['Yatırım Sayfasını Görebilir', 'Yatırım işlemlerine Onay yada Red verebilir', 'Yatırım Parasını Değiştirebilir', 'Yatırım Rezerve edebilir', 'Yatırım Hesabını Değiştirebilir', 'Yatırım Talebi Ekleyebilir.'],
                'Genel Yetkileri': ['Hesap detaylarını görüntüleyebilir', 'Yatırım Loglarını görüntüleyebilir', 'Hesap Loglarını görüntüleyebilir', 'Admin Loglarını görüntüleyebilir', 'Blacklisti görebilir'],
                'Çekim Yetkileri': ['Çekim taleplerini görüntüleyebilir', 'Çekim işlemlerine Onay yada Red verebilir.', 'Çekim Talebi ekleyebilir', 'Çekim Ödeme Talebi ekleyebilir', 'Dökumanları görüntüleyebilir'],
                'Hesap Yetkileri': ['Hesap sayfasını görüntüleyebilir', 'Yeni Hesap Ekleyebilir', 'Hesapları güncelleyebilir', 'Hesaplar arası bakiye transferi yapabilir', 'Hesap takviyesi yapabilir', 'Hesapları silebilir'],
                'Raporlar Yetkileri': ['Raporları görüntüleyebilir', 'Siteler raporlarını görüntüleyebilir', 'Site raporlarını görüntüleyebilir', 'Site raporu oluşturabilir', 'Site raporunda mütabık olabilir', 'Site yatırım raporlarını görüntüleyebilir', 'Site çekim raporlarını görüntüleyebilir', 'Kasa takviyelerini görüntüleyebilir', 'İşlem durumunu güncelleyebilir', 'Hesap eklemeleri görebilir'],
                'Admin Yetkileri': ['Admin listesini görüntüleyebilir', 'Admin ekleyebilir', 'Admin güncelleştirebilir', 'Admin silebilir'],
                'Site Yetkileri': ['Siteleri görüntüleyebilir', 'Site ekleyebilir', 'Site düzenleyebilir', 'Site kasa takviyesi yapabilir']
            }
        },

        {
            id: 2,
            name: 'Yönetici',
            description: 'Yönetim işlemlerini yapabilir. Adminden sonra en yetkili kişidir.',
            features: ['Anasayfa', 'Yatırım Talepleri', 'Çekim Talepleri', 'Hesap Yönetimi', 'Admin Yönetimi', 'Yetki Yönetimi', 'Rapor Yönetimi'],
            permissions: {
                'Anasayfa Yetkileri': ['Anasayfayı Görebilir', 'Yatırım Toplamını Görebilir', 'Çekim Toplamını Görebilir'],
                'Yatırım Yetkileri': ['Yatırım Sayfasını Görebilir', 'Yatırım işlemlerine Onay yada Red verebilir', 'Yatırım Parasını Değiştirebilir', 'Yatırım Rezerve edebilir', 'Yatırım Hesabını Değiştirebilir', 'Yatırım Talebi Ekleyebilir.'],
                'Genel Yetkileri': ['Hesap detaylarını görüntüleyebilir', 'Yatırım Loglarını görüntüleyebilir', 'Hesap Loglarını görüntüleyebilir', 'Admin Loglarını görüntüleyebilir', 'Blacklisti görebilir'],
                'Çekim Yetkileri': ['Çekim taleplerini görüntüleyebilir', 'Çekim işlemlerine Onay yada Red verebilir.', 'Çekim Talebi ekleyebilir', 'Çekim Ödeme Talebi ekleyebilir', 'Dökumanları görüntüleyebilir'],
                'Hesap Yetkileri': ['Hesap sayfasını görüntüleyebilir', 'Yeni Hesap Ekleyebilir', 'Hesapları güncelleyebilir', 'Hesaplar arası bakiye transferi yapabilir', 'Hesap takviyesi yapabilir', 'Hesapları silebilir'],
                'Raporlar Yetkileri': ['Raporları görüntüleyebilir', 'Siteler raporlarını görüntüleyebilir', 'Site raporlarını görüntüleyebilir', 'Site raporu oluşturabilir', 'Site raporunda mütabık olabilir', 'Site yatırım raporlarını görüntüleyebilir', 'Site çekim raporlarını görüntüleyebilir', 'Kasa takviyelerini görüntüleyebilir', 'İşlem durumunu güncelleyebilir', 'Hesap eklemeleri görebilir'],
                'Admin Yetkileri': ['Admin listesini görüntüleyebilir', 'Admin ekleyebilir', 'Admin güncelleştirebilir', 'Admin silebilir'],
                'Site Yetkileri': ['Siteleri görüntüleyebilir', 'Site ekleyebilir', 'Site düzenleyebilir', 'Site kasa takviyesi yapabilir']
            }
        },

        {
            id: 3,
            name: 'Ofis Müdürü',
            description: 'Ofis operasyonlarını yönetir.',
            features: ['Anasayfa', 'Yatırım Talepleri', 'Çekim Talepleri', 'Hesap Yönetimi', 'Rapor Yönetimi'],
            permissions: {
                'Anasayfa Yetkileri': ['Anasayfayı Görebilir', 'Yatırım Toplamını Görebilir', 'Çekim Toplamını Görebilir'],
                'Yatırım Yetkileri': ['Yatırım Sayfasını Görebilir', 'Yatırım işlemlerine Onay yada Red verebilir', 'Yatırım Parasını Değiştirebilir', 'Yatırım Rezerve edebilir', 'Yatırım Hesabını Değiştirebilir', 'Yatırım Talebi Ekleyebilir.'],
                'Genel Yetkileri': ['Hesap detaylarını görüntüleyebilir', 'Yatırım Loglarını görüntüleyebilir', 'Hesap Loglarını görüntüleyebilir', 'Blacklisti görebilir'],
                'Çekim Yetkileri': ['Çekim taleplerini görüntüleyebilir', 'Çekim işlemlerine Onay yada Red verebilir.', 'Çekim Talebi ekleyebilir', 'Çekim Ödeme Talebi ekleyebilir', 'Dökumanları görüntüleyebilir'],
                'Hesap Yetkileri': ['Hesap sayfasını görüntüleyebilir', 'Yeni Hesap Ekleyebilir', 'Hesapları güncelleyebilir', 'Hesaplar arası bakiye transferi yapabilir', 'Hesap takviyesi yapabilir'],
                'Raporlar Yetkileri': ['Raporları görüntüleyebilir', 'Site yatırım raporlarını görüntüleyebilir', 'Site çekim raporlarını görüntüleyebilir', 'İşlem durumunu güncelleyebilir'],
                'Admin Yetkileri': ['Admin listesini görüntüleyebilir', 'Admin ekleyebilir', 'Admin güncelleştirebilir', 'Admin silebilir'],
                'Site Yetkileri': ['Siteleri görüntüleyebilir', 'Site ekleyebilir', 'Site düzenleyebilir', 'Site kasa takviyesi yapabilir']
            }
        },

        {
            id: 4,
            name: 'Teknik',
            description: 'Teknik destek ve işlemlerden sorumlu.',
            features: ['Anasayfa', 'Yatırım Talepleri', 'Çekim Talepleri', 'Hesap Yönetimi'],
            permissions: {
                'Anasayfa Yetkileri': ['Anasayfayı Görebilir', 'Yatırım Toplamını Görebilir', 'Çekim Toplamını Görebilir'],
                'Yatırım Yetkileri': ['Yatırım Sayfasını Görebilir', 'Yatırım işlemlerine Onay yada Red verebilir', 'Yatırım Parasını Değiştirebilir', 'Yatırım Rezerve edebilir', 'Yatırım Hesabını Değiştirebilir', 'Yatırım Talebi Ekleyebilir.'],
                'Genel Yetkileri': ['Hesap detaylarını görüntüleyebilir', 'Yatırım Loglarını görüntüleyebilir', 'Hesap Loglarını görüntüleyebilir'],
                'Çekim Yetkileri': ['Çekim taleplerini görüntüleyebilir', 'Çekim işlemlerine Onay yada Red verebilir.', 'Çekim Talebi ekleyebilir', 'Çekim Ödeme Talebi ekleyebilir', 'Dökumanları görüntüleyebilir'],
                'Hesap Yetkileri': ['Hesap sayfasını görüntüleyebilir', 'Yeni Hesap Ekleyebilir', 'Hesapları güncelleyebilir', 'Hesaplar arası bakiye transferi yapabilir', 'Hesap takviyesi yapabilir'],
                'Raporlar Yetkileri': ['Raporları görüntüleyebilir', 'Site yatırım raporlarını görüntüleyebilir', 'Site çekim raporlarını görüntüleyebilir', 'İşlem durumunu güncelleyebilir'],
                'Admin Yetkileri': ['Admin Yetkisi Bulunmamaktadır'],
                'Site Yetkileri': ['Site Yetkisi Bulunmamaktadır']
            }
        },

        {
            id: 5,
            name: 'Personel',
            description: 'Günlük işlemler ve görevleri yürütür.',
            features: ['Anasayfa', 'Yatırım Talepleri', 'Çekim Talepleri', 'Hesap Yönetimi'],
            permissions: {
                'Anasayfa Yetkileri': ['Anasayfayı Görebilir', 'Yatırım Toplamını Görebilir', 'Çekim Toplamını Görebilir'],
                'Yatırım Yetkileri': ['Yatırım Sayfasını Görebilir', 'Yatırım işlemlerine Onay yada Red verebilir', 'Yatırım Parasını Değiştirebilir', 'Yatırım Rezerve edebilir', 'Yatırım Hesabını Değiştirebilir', 'Yatırım Talebi Ekleyebilir.'],
                'Genel Yetkileri': ['Hesap detaylarını görüntüleyebilir', 'Yatırım Loglarını görüntüleyebilir', 'Hesap Loglarını görüntüleyebilir'],
                'Çekim Yetkileri': ['Çekim taleplerini görüntüleyebilir', 'Çekim işlemlerine Onay yada Red verebilir.', 'Çekim Talebi ekleyebilir', 'Çekim Ödeme Talebi ekleyebilir', 'Dökumanları görüntüleyebilir'],
                'Hesap Yetkileri': ['Hesap sayfasını görüntüleyebilir', 'Yeni Hesap Ekleyebilir', 'Hesapları güncelleyebilir', 'Hesaplar arası bakiye transferi yapabilir', 'Hesap takviyesi yapabilir'],
                'Raporlar Yetkileri': ['Raporları görüntüleyebilir', 'Site yatırım raporlarını görüntüleyebilir', 'Site çekim raporlarını görüntüleyebilir', 'İşlem durumunu güncelleyebilir'],
                'Admin Yetkileri': ['Admin Yetkisi Bulunmamaktadır'],
                'Site Yetkileri': ['Site Yetkisi Bulunmamaktadır']
            }
        },

    ];

    return (
        <div className="role-management border-2 h-screen bg-white ">
            <h2 className='text-xl font-bold my-4 '>Yetki Yönetimi Listesi</h2>

            <div className='flex items-center justify-center'>

                <select onChange={handleRoleChange} className="border rounded-md text-xs ml-1 p-2 mb-4">
                    <option value="">Seçiniz</option>
                    {roles.map((role) => (
                        <option key={role.id} value={role.id}>{role.name}</option>
                    ))}
                </select>
            </div>


            {selectedRole && (

                <div className="role-info-box">

                    <h3>{selectedRole.name}</h3>
                    <p>{selectedRole.description}</p>
                    {Object.keys(selectedRole.permissions).map((permissionType) => (
                        <div key={permissionType} className="permission-box font-semibold mt-1">
                            <button onClick={() => togglePermissionBox(permissionType)} className="cursor-pointer border-2 rounded-md p-1 bg-blue-600 text-slate-200 font-sans text-xs">
                                {permissionType}
                            </button>
                            {openPermissions[permissionType] && (
                                <div className='flex flex-col my-1 border-2 pl-1 text-xs gap-3 p-2 items-start justify-center'>
                                    {selectedRole.permissions[permissionType].map((perm, index) => (
                                        <span key={index} className='text-xs flex'> ≈ {perm}</span>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}

                    <table className="feature-table">
                        <thead>
                            <tr>
                                <th>Sayfa</th>
                                <th>Erişim</th>
                            </tr>
                        </thead>
                        <tbody>
                            {['Anasayfa', 'Yatırım Talepleri', 'Çekim Talepleri', 'Hesap Yönetimi', 'Admin Yönetimi', 'Yetki Yönetimi', 'Rapor Yönetimi', 'Blacklist', 'Refs'].map((feature) => (
                                <tr className='text-xs' key={feature}>
                                    <td>{feature}</td>
                                    <td style={getFeatureStyle(selectedRole.features.includes(feature))}>
                                        {selectedRole.features.includes(feature) ? 'Evet' : 'Hayır'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default RoleManagementTable;
