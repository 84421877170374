import React, { useState, useEffect } from 'react';
import '../App.css'

const CurrentDateTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const formattedDate = currentTime.toLocaleDateString("tr-TR", {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  const formattedTime = currentTime.toLocaleTimeString("tr-TR");

  return (
    <div className='flex items-center justify-center'>
      <p>{formattedDate} {formattedTime}</p>
    </div>
  );
};

export default CurrentDateTime;
