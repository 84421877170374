import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import Kasalar from '../components/Kasalar'


function KasalarPage() {
  return (
    <div>
      <Header />
      <NavBar />
      <Kasalar />
  
    </div>
  )
}

export default KasalarPage
