import React from 'react'
import Header from '../components/Header.jsx'
import NavBar from '../components/NavBar.jsx'
import CekimContent from '../components/CekimContent.jsx'


function CekimTalepleri() {

  
  return (
      <>
        <Header />
        <NavBar />
        <CekimContent/>
      </>
  )
}

export default CekimTalepleri
