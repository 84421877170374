import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import SiteRaporlar from '../components/SiteRaporlar'


function SiteRaporlarPage() {
  return (
    <div>
        <Header />
        <NavBar />
        <SiteRaporlar />
    </div>
  )
}

export default SiteRaporlarPage