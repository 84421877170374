import React, { useState, useEffect } from 'react';
import DateTime from '../components/DateTime';
import { FaMoneyBillTransfer, FaChartLine, FaCircleCheck, FaClock } from 'react-icons/fa6';
import Bankaİmg from '../img/banka-havalesi.png';
import axios from 'axios';

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;


const BankaCardBox = () => {


    const hesapPaylasimi = true;
    const [, setError] = useState(null);
    const [, setInvestments] = useState([]);
    const [, setWithdrawals] = useState([]);
    const [investmentTotals, setInvestmentTotals] = useState({ approvedAmount: 0, approvedCount: 0, pendingAmount: 0, pendingCount: 0 });
    const [withdrawalTotals, setWithdrawalTotals] = useState({ approvedAmount: 0, approvedCount: 0, pendingAmount: 0, pendingCount: 0 });
    const [kasaBalance, setKasaBalance] = useState(0);



  const formatTurkishLira = (amount) => {
    const numAmount = Number(amount) || 0;
    return new Intl.NumberFormat('tr-TR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numAmount);
  };


    // Yatırım fonk.
    const fetchInvestments = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/investment/`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const dataResponse = response.data.data;
            setInvestments(dataResponse);
            calculateTransactionTotals(dataResponse, setInvestmentTotals);
        } catch (error) {
            console.error("Yatırımlar getirilirken hata oluştu:", error);
        }
    };


    // Çekim fonk.
    const fetchWithdrawals = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/withdrawal/`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const dataResponse = response.data.data;
            setWithdrawals(dataResponse);
            calculateTransactionTotals(dataResponse, setWithdrawalTotals);
        } catch (error) {
            console.error("Çekim işlemleri getirilirken hata oluştu:", error);
        }
    };


    // Onaylanan ve bekleyen işlemlerin toplamlarını hesaplayan fonksiyon
    const calculateTransactionTotals = (transactions, setTotals) => {

        let approvedSum = 0, approvedTransactions = 0, pendingSum = 0, pendingTransactions = 0;

        transactions.forEach((transaction) => {

            if (transaction.investmentStatus === 'Onaylandı') {
                approvedSum += transaction.balance;
                approvedTransactions += 1;
            } else if (transaction.investmentStatus === 'Beklemede') {
                pendingSum += transaction.balance;
                pendingTransactions += 1;
            };

            if (transaction.withdrawalStatus === 'Onaylandı') {
                approvedSum += transaction.balance;
                approvedTransactions += 1;
            } else if (transaction.withdrawalStatus === 'Beklemede') {
                pendingSum += transaction.balance;
                pendingTransactions += 1;
            }
        });

        setTotals({
            approvedAmount: approvedSum,
            approvedCount: approvedTransactions,
            pendingAmount: pendingSum,
            pendingCount: pendingTransactions
        });
    };

    useEffect(() => {
        fetchInvestments();
        fetchWithdrawals();
        fetchRefs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchRefs = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/refs/with_investments/count`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const mgmtRef = response.data.find(ref => ref.refName === 'mgmt');
            if (mgmtRef) {
                setKasaBalance(mgmtRef.balance);
            }
        } catch (error) {
            setError('Veri yüklenemedi.');
        }
    };



    return (
        <div className="bg-white rounded-lg overflow-hidden w-[22rem]">
            {/* Başlık */}
            <div className="bg-blue-600 px-6 py-2">
                <div className="flex items-center justify-between">
                    <h2 className="text-xs font-semibold text-white">Banka Ödeme</h2>
                    <img src={Bankaİmg} alt='kredi-img' className='h-10' />
                </div>
            </div>

            {/* İçerik */}
            <div className="p-3 space-y-4">
                {/* Tarih-Saat */}
                <div className="flex items-center text-xs text-gray-500">
                    <DateTime />
                </div>

                {/* Toplam Bakiye */}
                <div className="bg-emerald-50 rounded-lg p-1">
                    <div className="flex items-center justify-between">
                        <span className="text-xs font-medium text-gray-600">Kasa: 
                        </span>
                        <span className="text-sm font-bold text-emerald-600">
                        {formatTurkishLira(kasaBalance)} ₺
                        </span>
                    </div>
                </div>

                {/* Hesap Paylaşımı */}
                <div className="bg-gray-50 rounded-lg p-1">
                    <div className="flex items-center justify-between mb-2">
                        <span className="text-xs font-medium text-gray-600">Hesap Paylaşımı</span>
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            hesapPaylasimi ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                        }`}>
                            {hesapPaylasimi ? 'Var' : 'Yok'}
                        </span>
                    </div>
                </div>

                {/* Yatırımlar ve Çekimler */}
                <div className="space-y-4">
                    {/* Yatırım Bölümü */}
                    <div className="rounded-lg border border-gray-200 px-4 py-1">
                        <h3 className="text-xs font-medium text-gray-700 mb-4 flex items-center">
                            <FaChartLine className="mr-2 text-emerald-500" />
                            Yatırım
                        </h3>
                        <div className="grid grid-cols-2 gap-4">
                            {/* Onaylananlar */}
                            <div className="space-y-1">
                                <div className="flex items-center text-xs text-gray-600">
                                    <FaCircleCheck className="mr-1 text-green-500" />
                                    <span>Onaylananlar</span>
                                </div>
                                <div className="font-semibold text-green-600">
                                    {Number(investmentTotals.approvedAmount).toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ₺
                                </div>
                                <div className="text-xs text-gray-500">
                                    {investmentTotals.approvedCount} işlem
                                </div>
                            </div>
                            {/* Bekleyenler */}
                            <div className="space-y-1">
                                <div className="flex items-center text-xs text-gray-600">
                                    <FaClock className="mr-1 text-blue-500" />
                                    <span>Bekleyenler</span>
                                </div>
                                <div className="font-semibold text-blue-600">
                                    {Number(investmentTotals.pendingAmount).toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ₺
                                </div>
                                <div className="text-xs text-gray-500">
                                    {investmentTotals.pendingCount} işlem
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Çekim Bölümü */}
                    <div className="rounded-lg border border-gray-200 px-4 py-2">
                        <h3 className="text-xs font-medium text-gray-700 mb-4 flex items-center">
                            <FaMoneyBillTransfer className="mr-2 text-emerald-500" />
                            Çekim
                        </h3>
                        <div className="grid grid-cols-2 gap-4">
                            {/* Onaylananlar */}
                            <div className="space-y-1">
                                <div className="flex items-center text-xs text-gray-600">
                                    <FaCircleCheck className="mr-1 text-green-500" />
                                    <span>Onaylananlar</span>
                                </div>
                                <div className="font-semibold text-green-600">
                                    {Number(withdrawalTotals.approvedAmount).toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ₺
                                </div>
                                <div className="text-xs text-gray-500">
                                    {withdrawalTotals.approvedCount} işlem
                                </div>
                            </div>
                            {/* Bekleyenler */}
                            <div className="space-y-1">
                                <div className="flex items-center text-xs text-gray-600">
                                    <FaClock className="mr-1 text-blue-500" />
                                    <span>Bekleyenler</span>
                                </div>
                                <div className="font-semibold text-blue-600">
                                    {Number(withdrawalTotals.pendingAmount).toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ₺
                                </div>
                                <div className="text-xs text-gray-500">
                                    {withdrawalTotals.pendingCount} işlem
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankaCardBox;