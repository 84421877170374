import './App.css';
import './index.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Yatırım from './pages/yatırım-talepleri.jsx';
import Cekim from './pages/cekim-talepleri.jsx';
import HesapYonetimi from './pages/hesap-yonetimi.jsx';
import Login from './pages/login.jsx';
import Home from './pages/index.jsx';
import NotFound from './pages/404-notfound.jsx';
import AdminYonetimi from './pages/admin-yonetimi.jsx';
import YetkiYonetimi from './pages/yetki-yonetimi.jsx';
import Blacklist from './pages/blacklist.jsx';
import RefsTable from './pages/yonetim.jsx';
import ParavanYatırım from './pages/paravan-yatırım.jsx';
import ParavanCekim from './pages/paravan-cekim.jsx';
import HesapKasaTakviyeleri from './pages/hesapkasa-takviyeleri.jsx';
import HesapYatırımlarıPage from './pages/hesap-yatırımları.jsx';
import HesapCekimleriPage from './pages/hesap-cekimleri.jsx';
import HesapİstatistikPage from './pages/hesap-istatistik.jsx';
import PrivateRoute from './components/PrivateRoute.jsx';
import PublicRoute from './components/PublicRoute.jsx';
import HesapEklemeleriPage from './pages/hesap-eklemeleri.jsx';
import KriptoYatırımPage from './pages/kripto-yatırım.jsx';
import KasalarPage from './pages/kasalar.jsx';
import KasaTakviyeleriPage from './pages/kasaTakviyeleri.jsx';
import Siteler from './pages/siteler.jsx';
import SiteİstatistikPage from './pages/site-istatistik.jsx';
import SiteRaporlarıPage from './pages/site-raporları.jsx';


function App() {

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    const currentPath = window.location.pathname;

    // If no token, redirect to login page
    if (!token) {
      if (currentPath !== '/login' &&
        currentPath !== '/paravan_yatirim' &&
        currentPath !== '/paravan_cekim') {
        navigate('/login');
      }
    } else {
      if (currentPath === '/login') {
        navigate('/');
      }
    }
  }, [navigate]);

  return (
    <div className="Dashboard">
      <Routes>

        <Route path="/login" element={
          <PublicRoute component={Login} />
        } />

        <Route path="/paravan_yatirim" element={
          <PublicRoute component={ParavanYatırım} />
        } />
        <Route path="/paravan_cekim" element={
          <PublicRoute component={ParavanCekim} />
        } />

        <Route
          path="/"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Teknik', 'Personel', 'Master-Admin', 'Manager', 'Ofis Müdürü', 'Super Admin']} component={Home} />
          }
        />

        <Route
          path="/yatırım-talepleri"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Teknik', 'Personel', 'Master-Admin', 'Manager', 'Ofis Müdürü', 'Super Admin']} component={Yatırım} />
          }
        />

        <Route
          path="/cekim-talepleri"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Teknik', 'Personel', 'Master-Admin', 'Manager', 'Ofis Müdürü', 'Super Admin']} component={Cekim} />
          }
        />

        <Route
          path="/hesap-yonetimi"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Teknik', 'Personel', 'Master-Admin', 'Manager', 'Ofis Müdürü', 'Super Admin']} component={HesapYonetimi} />
          }
        />

        <Route
          path="/yetki-yonetimi"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Super Admin', 'Master Admin', 'Ofis Müdürü', 'Master-Admin', 'Manager']} component={YetkiYonetimi} />
          }
        />

        <Route
          path="/admin-yonetimi"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Super Admin', 'Master-Admin']} component={AdminYonetimi} />
          }
        />

        <Route
          path="/blacklist"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Master-Admin', 'Manager', 'Super Admin']} component={Blacklist} />
          }
        />

        <Route
          path="/yonetim"
          element={
            <PrivateRoute allowedRoles={['Master-Admin', 'Super Admin']} component={RefsTable} />
          }
        />

        <Route
          path="/rapor-yonetimi/hesapkasa_takviyeleri"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Master-Admin', 'Manager', 'Super Admin', 'Ofis Müdürü']} component={HesapKasaTakviyeleri} />
          }
        />

        <Route
          path="/rapor-yonetimi/siteler"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Master-Admin', 'Manager', 'Super Admin', 'Ofis Müdürü']} component={Siteler} />
          }
        />

        <Route
          path="/rapor-yonetimi/site_istatistik"
          element={
            <PrivateRoute allowedRoles={['Master-Admin', 'Super Admin']} component={SiteİstatistikPage} />
          }
        />

        <Route
          path="/rapor-yonetimi/site_raporları"
          element={
            <PrivateRoute allowedRoles={['Master-Admin', 'Super Admin']} component={SiteRaporlarıPage} />
          }
        />

        <Route
          path="/rapor-yonetimi/yatırımlar"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Master-Admin', 'Manager', 'Ofis Müdürü', 'Super Admin']} component={HesapYatırımlarıPage} />
          }
        />

        <Route
          path="/rapor-yonetimi/cekimler"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Master-Admin', 'Manager', 'Ofis Müdürü', 'Super Admin']} component={HesapCekimleriPage} />
          }
        />

        <Route
          path="/rapor-yonetimi/hesap_istatistik"
          element={
            <PrivateRoute allowedRoles={['Super Admin', 'Master-Admin']} component={HesapİstatistikPage} />
          }
        />

        <Route
          path="/rapor-yonetimi/hesap_eklemeleri"
          element={
            <PrivateRoute allowedRoles={['Super Admin', 'Master-Admin', 'Admin', 'Manager', 'Ofis Müdürü']} component={HesapEklemeleriPage} />
          }
        />


        <Route
          path="/kripto_yatırım"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Master-Admin', 'Super Admin']} component={KriptoYatırımPage} />
          }
        />

        <Route
          path="/rapor-yonetimi/kasalar"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Master-Admin', 'Super Admin']} component={KasalarPage} />
          }
        />

        <Route
          path="/rapor-yonetimi/kasa_takviyeleri"
          element={
            <PrivateRoute allowedRoles={['Admin', 'Master-Admin', 'Super Admin']} component={KasaTakviyeleriPage} />
          }
        />

        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
