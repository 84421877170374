import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import HesapCekimleri from '../components/HesapCekimleri';

function HesapCekimleriPage() {
  return (
    <div>
      <Header/>
      <NavBar />
      <HesapCekimleri />
    </div>
  )
}
export default HesapCekimleriPage
