import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ConfirmBox from './ConfirmBox';
import UserUpdateForm from './UserUpdateForm'

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;

const UsersTable = () => {

    
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [updateModal, setUpdateModal] = useState('');

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${BASE_URL}/users`);
            const sortedUsers = response.data.data.sort((a, b) => 
                new Date(b.created_at) - new Date(a.created_at)
            );
            setUsers(sortedUsers);
            setError(null);
        } catch (error) {
            console.error("Error fetching users:", error);
            setError("Failed to load users. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleDeleteUser = async (userId) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${BASE_URL}/user/${userId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            await fetchUsers();
            setConfirmModal(false);
        } catch (error) {
            console.error("Error deleting user:", error);
            setError("Failed to delete user. Please try again.");
        }
    };

    const handleUpdateUser = async (userId) => {
        const token = localStorage.getItem('token');
        try {
            await axios.patch(`${BASE_URL}/user/${userId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            await fetchUsers();
            setUpdateModal(false);
        
        } catch (error) {
            console.error("Error deleting user:", error);
            setError("");
        }
    };




    const filteredUsers = users.filter(user => 
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
            </div>
        );
    }

    return (
        <div className='top-0 bottom-0 h-screen right-0 left-0'>
        <div className="container-fluid mx-auto p-3">
            <div className="bg-white rounded-lg shadow-lg  overflow-hidden">
                {/* Search and filters */}
                <div className="p-4 bg-gray-300">
                    <div className="flex items-center space-x-4">
                        <div className="flex-1">
                            <input
                                type="search"
                                placeholder="Kullanıcı Ara..."
                                className="w-full px-4 py-1 rounded-lg border text-xs focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <button
                            onClick={fetchUsers}
                            className="px-4 py-1 bg-emerald-500 text-white rounded-lg text-xs hover:bg-emerald-600 transition-colors"
                        >
                            Yenile
                        </button>
                    </div>
                </div>

                {error && (
                    <div className="p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
                        <p className="font-medium">Hata!</p>
                        <p>{error}</p>
                    </div>
                )}

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-800">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                    ID
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                    Kullanıcı Adı
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                    E-Mail
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                    Adı Soyadı
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                    Rolü
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-slate-200 uppercase tracking-wider">
                                    Oluşturulma Tarihi
                                </th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">
                                    Aktif
                                </th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-slate-200 uppercase tracking-wider">
                                    Detaylar
                                </th>
                            </tr>
                        </thead>
               
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredUsers.map((user) => (
                                <tr key={user.id} className="hover:bg-gray-100">
                                    <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                                        {user.id}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">
                                        {user.username}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                                        {user.email}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-900">
                                        {user.fullName}
                                    </td>
                                    
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                            {user.role}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                                        {new Date(user.created_at).toLocaleString("tr-TR")}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-center">
                                        <div className="flex items-center justify-center">
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    className="sr-only peer"
                                                    checked={user.is_active}
                                                    readOnly
                                                />
                                                <div className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-emerald-300 rounded-full peer 
                                                    ${user.is_active ? 'peer-checked:bg-emerald-500' : ''} 
                                                    peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                                                    after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 
                                                    after:border after:rounded-full after:h-5 after:w-5 after:transition-all`}>
                                                </div>
                                            </label>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-center">
                                        <div className="flex items-center justify-center space-x-2">
                                            <button
                                                className="px-3 py-1 bg-emerald-500 text-white rounded-md hover:bg-emerald-600 transition-colors text-xs"
                                                onClick={() => {
                                                    setSelectedUserId(user.id);
                                                    setUpdateModal(true);
                                                }}
                                            >
                                            Düzenle
                                            
                                            </button>
                                            <button
                                                className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors text-xs"
                                                onClick={() => {
                                                    setSelectedUserId(user.id);
                                                    setConfirmModal(true);
                                                }}
                                            >
                                                Sil
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Show message if no users found */}
                {filteredUsers.length === 0 && (
                    <div className="text-center py-6 text-gray-500">
                        Aradığın kriterde bir hesap bulunamadı.
                    </div>
                )}
            </div>

    
            {confirmModal && (
                <ConfirmBox
                    show={confirmModal}
                    title="Bu hesabı silmek istediğinden emin misin?"
                    onSuccess={() => handleDeleteUser(selectedUserId)}
                    onClose={() => {
                        setConfirmModal(false);
                        setSelectedUserId(null);
                    }}
                />
            )}


            {updateModal && (
                <UserUpdateForm
                    show={updateModal}
                    title="Admin Hesabı Güncelle"
                    onSuccess={() => handleUpdateUser(selectedUserId)}
                    userId={selectedUserId}
                    onClose={() => {
                        setUpdateModal(false);
                        setSelectedUserId(null);
                    }}
                />
            )}

          

</div>
        </div>
    );
};

export default UsersTable;