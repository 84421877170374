import React from 'react'
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import Siteistatistik from '../components/Siteİstatistik';


function SiteİstatistikPage() {
  return (
    <div>
        <Header />
        <NavBar />
        <Siteistatistik />
    </div>

  )
}

export default SiteİstatistikPage