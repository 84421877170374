import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;

const UserUpdateForm = ({ show, onClose, title, userId }) => {
    const [formData, setFormData] = useState({
        fullName: '',
        username: '',
        password: '',
        note: '',
        role: '',
        skype: '',
        email: '',
        is_active: false,
        is_admin: false
    });

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (userId) {
            fetchUserDetails();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const fetchUserDetails = async () => {
        setIsLoading(true);
        setError(null);
        
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.get(`${BASE_URL}/users/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                }
            });

            if (!response.data) {
                throw new Error('User not found');
            }

            setFormData({
                fullName: response.data.fullName || '',
                username: response.data.username || '',
                email: response.data.email || '',
                role: response.data.role || '',
                skype: response.data.skype || '',
                note: response.data.note || '',
                is_active: Boolean(response.data.is_active),
                is_admin: Boolean(response.data.is_admin)
            });
        } catch (error) {
            console.error('Error fetching user details:', error);
            setError(error.message || 'Failed to fetch user details');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const updateData = {
                fullName: formData.fullName || null,
                username: formData.username || null,
                email: formData.email || null,
                role: formData.role || null,
                skype: formData.skype || null,
                note: formData.note || null,
                is_active: Boolean(formData.is_active),
                is_admin: Boolean(formData.is_admin)
            };

            const cleanedData = Object.fromEntries(
                Object.entries(updateData).filter(([_, value]) => value !== null)
            );

            await axios.patch(
                `${BASE_URL}/user/${userId}`,
                cleanedData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                    }
                }
            );

            setShowModal(true);
        } catch (error) {
            console.error('Error updating user:', error);
            setError(error.response?.data?.detail || 'Failed to update user');
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleCloseModal = () => {
        setShowModal(false);
        onClose();
        window.location.reload();
    };

    if (!show) return null;

    if (isLoading) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg">
                    <p className="text-lg font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4" style={{ zIndex: '9999' }}>
                <div className="bg-white rounded-lg w-full max-w-2xl">
                    <div className="bg-emerald-600 p-4 rounded-t-lg">
                        <h1 className="text-white font-bold text-xl text-center">{title}</h1>
                    </div>

                    {error && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 m-4 rounded relative">
                            <strong className="font-bold">Error! </strong>
                            <span className="block sm:inline">{error}</span>
                            <button 
                                onClick={fetchUserDetails}
                                className="text-blue-500 underline ml-4"
                            >
                                Retry
                            </button>
                        </div>
                    )}

                    <form onSubmit={handleUpdate} className="p-6 space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Basic Information */}
                            <div className="space-y-4">
                                <h2 className='text-xl md:text-2xl font-bold underline underline-offset-4 my-4 mb-8'>
                                    Kullanıcı Bilgileri
                                </h2>

                                <div>
                                    <label className="block text-sm font-bold mb-2">Adı Soyadı</label>
                                    <input
                                        type="text"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="Adı Soyadı"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold mb-2">Kullanıcı Adı</label>
                                    <input
                                        type="text"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="Kullanıcı Adı"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold mb-2">E-Mail</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="E-Mail"
                                    />
                                </div>

                                <div className="flex gap-4">
                                    <label className="flex items-center space-x-2">
                                        <input
                                            type="checkbox"
                                            name="is_active"
                                            checked={formData.is_active}
                                            onChange={handleInputChange}
                                            className="w-4 h-4"
                                        />
                                        <span className="text-sm font-bold">Hesap Aktif</span>
                                    </label>

                              
                                </div>
                            </div>

                            {/* Additional Information */}
                            <div className="space-y-4">
                                <h2 className='text-xl md:text-2xl font-bold underline underline-offset-4 my-4 mb-8'>
                                    Ek Bilgiler
                                </h2>

                                <div>
                                    <label className="block text-sm font-bold mb-2">Not</label>
                                    <input
                                        type="text"
                                        name="note"
                                        value={formData.note}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="Not"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold mb-2">Skype</label>
                                    <input
                                        type="text"
                                        name="skype"
                                        value={formData.skype}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="Skype ID"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold mb-2">Rolü</label>
                                    <select
                                        name="role"
                                        value={formData.role}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                    >
                                        <option value="">Seçiniz</option>
                                        <option value="Admin">Admin</option>
                                        <option value="Manager">Yönetici</option>
                                        <option value="Ofis Müdürü">Ofis müdürü</option>
                                        <option value="Teknik">Teknik</option>
                                        <option value="Personel">Personel</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end gap-4 pt-4">
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="bg-emerald-500 text-white px-4 text-xs py-2 rounded-md hover:bg-emerald-600 disabled:opacity-50"
                            >
                                {isLoading ? 'Güncelleniyor...' : 'Güncelle'}
                            </button>

                            <button
                                type="button"
                                onClick={onClose}
                                disabled={isLoading}
                                className="bg-red-500 text-white px-4 text-xs py-2 rounded-md hover:bg-red-600 disabled:opacity-50"
                            >
                                Kapat
                            </button>
                        </div>

                        <div className="text-center text-sm text-gray-600 bg-gray-100 p-4 rounded-md mt-6">
                            <p className="font-semibold">Lütfen tüm alanları eksiksiz doldurunuz.</p>
                            <p>Anlayışınız için teşekkür ederiz.</p>
                            <p>Dem<span className="font-bold">Fix</span></p>
                        </div>
                    </form>
                </div>

                {showModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white flex flex-col items-center justify-center p-6 rounded-lg">
                            <h2 className="text-xl font-bold mb-4">Kullanıcı Başarıyla Güncellendi</h2>
                            <button
                                onClick={handleCloseModal}
                                className="bg-red-500 text-white px-2 w-14 text-xs text-center py-2 rounded-md hover:bg-red-600"
                            >
                                Kapat
                            </button>
                        </div>
                    </div>
                )}
            </div>
    );
};

export default UserUpdateForm;