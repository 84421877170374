import React, { useState } from 'react';
import axios from 'axios';
import { IoIosClose } from "react-icons/io";
import { FaMoneyBill, FaStickyNote, FaCheckCircle } from "react-icons/fa";

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;

const KasaCıkarBox = ({ show, onClose, accountId, accountBalance }) => {
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    if (!show) return null;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!amount || isNaN(parseFloat(amount))) {
            setError('Lütfen bir para miktarı giriniz.');
            return;
        }

        if (parseFloat(amount) > parseFloat(accountBalance)) {
            setError('Bu para çekme işlemi için yeterli bakiye yok');
            return;
        }

        const token = localStorage.getItem('token');
        
        const payload = {
            accountBalance: parseFloat(amount),
            accountExplanation: message || '',
        };

        try {
            await axios.patch(`${BASE_URL}/bank/sub_balance/${accountId}`, payload, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            setSuccess(true);
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1500);
        } catch (error) {
            console.error('Update error:', error);
            setError(
                error.response?.data?.detail ||
                error.response?.data?.message ||
                'İsteğiniz işlenirken bir hata oluştu.'
            );  
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-md overflow-hidden">
                {/* Header */}
                <div className="bg-yellow-500 px-6 py-4 flex items-center justify-between">
                    <div className="flex items-center space-x-2 text-white">
                        <FaMoneyBill className="text-xl" />
                        <h2 className="text-lg font-medium">Kasa Çıkar</h2>
                    </div>
                    <button
                        onClick={onClose}
                        className="text-white hover:text-gray-200 transition-colors"
                    >
                        <IoIosClose className="text-2xl" />
                    </button>
                </div>

                {/* Content */}
                <form onSubmit={handleSubmit} className="p-6 space-y-6">
                    {/* Current Balance Display */}
                    <div className="bg-gray-50 rounded-md p-4">
                        <div className="flex items-center justify-between text-sm">
                            <span className="text-gray-600">Mevcut Bakiye:</span>
                            <span className="font-medium text-gray-900">
                                {Number(accountBalance).toLocaleString('tr-TR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })} ₺
                            </span>
                        </div>
                    </div>

                    {success && (
                        <div className="bg-green-50 border border-green-200 rounded-md p-4">
                            <div className="flex items-center justify-center text-green-600">
                                <FaCheckCircle className="mr-2" />
                                <span className="text-sm font-medium">Hesaptan başarıyla takviye alındı.</span>
                            </div>
                        </div>
                    )}

                    {/* Amount Input */}
                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            <div className="flex items-center mb-1">
                                <FaMoneyBill className="mr-2 text-yellow-600" />
                                Çıkarılacak Tutar
                            </div>
                        </label>
                        <div className="relative">
                            <input
                                type="number"
                                placeholder="Miktar giriniz."
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-sm"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            <span className="absolute right-3 top-2 text-gray-400 text-sm">₺</span>
                        </div>
                    </div>

                    {/* Note Input */}
                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                            <div className="flex items-center mb-1">
                                <FaStickyNote className="mr-2 text-yellow-600" />
                                Not
                            </div>
                        </label>
                        <input
                            type="text"
                            placeholder="Not Belirtiniz."
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500 text-sm"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>

                    {/* Error Message */}
                    {error && (
                        <div className="bg-red-50 border border-red-200 rounded-md p-4">
                            <div className="flex text-red-600 text-sm">
                                <svg className="h-5 w-5 text-red-400 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                                {error}
                            </div>
                        </div>
                    )}

                    {/* Submit Button */}
                    <div className="flex justify-end pt-4">
                        <button
                            type="submit"
                            className="px-6 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={success}
                        >
                            Çıkar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default KasaCıkarBox;