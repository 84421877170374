import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  
  const token = localStorage.getItem('token');
  
  if (token && restricted) {
    return <Navigate to="/" />;
  }

  return <Component />;
};

export default PublicRoute;