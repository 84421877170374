import React, { useEffect, useState } from 'react';
import '../App.css';
import BankaForm from './BankaForm';
import BankTable from './BankTable';
import DateInput from './DateInput';
import TransferBox from './TransferBox';
import axios from 'axios';
import HesapDurumNavBar from './HesapDurumNavBar';

const BASE_URL = process.env.REACT_APP_API_URL;

const HesapContent = () => {

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [, setLoading] = useState(false);
  const [, setError] = useState(null);
  
  const [filterConfig, setFilterConfig] = useState({
    startDate: null,
    endDate: null,
    autoUpdate: true
  });

  useEffect(() => {
    fetchBankAccounts();
  }, []);

  // Date range filtering
  useEffect(() => {
    let result = bankAccounts;

    // Apply date filtering if start or end date is set
    if (filterConfig.startDate || filterConfig.endDate) {
      result = bankAccounts.filter(account => {
        const accountDate = new Date(account.created_at);

        // Check start date condition
        const meetsStartDateCondition = !filterConfig.startDate ||
          accountDate >= new Date(filterConfig.startDate);

        // Check end date condition
        const meetsEndDateCondition = !filterConfig.endDate ||
          accountDate <= new Date(filterConfig.endDate);

        return meetsStartDateCondition && meetsEndDateCondition;
      });
    }

    setFilteredAccounts(result);
  }, [filterConfig.startDate, filterConfig.endDate, bankAccounts]);

  const fetchBankAccounts = async () => {
    const token = localStorage.getItem('token');
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/bank/accounts`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const accounts = response.data.data;
      setBankAccounts(accounts);
      setFilteredAccounts(accounts);
    } catch (err) {
      setError('Banka hesapları yüklenirken bir hata oluştu');
      console.error('Error fetching bank accounts:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (dates) => {
    // Ensure dates are converted to ISO string or null
    const startDate = dates.startDate ? new Date(dates.startDate).toISOString() : null;
    const endDate = dates.endDate ? new Date(dates.endDate).toISOString() : null;

    setFilterConfig(prev => ({
      ...prev,
      startDate,
      endDate
    }));
  };

  return (
    <div className="w-full border-4 bg-slate-200 border-slate-200">
      <div className="flex flex-col p-5 border-4 items-start justify-center">
        <div className="flex items-center justify-between border border-black border-r-0 border-t-0 border-l-0 pb-2 flex-wrap gap-4 w-full">
          <div className='flex items-start gap-2 '>
            <button
              onClick={() => setShowModal(true)}
              type="button"
              className="p-1.5 text-xs rounded-lg bg-emerald-500 text-white hover:bg-emerald-600 font-sans  transition-colors"
            >
              Banka Hesabı Ekle
            </button>

            <button
              onClick={() => setShowModal2(true)}
              type="button"
              className="p-1.5 text-xs rounded-lg bg-yellow-600 text-white hover:bg-yellow-700 font-sans transition-colors"
            >
              Transfer Et
            </button>
          </div>

          <div className="flex flex-row items-center text-sm gap-3 justify-center">
            <DateInput onDateChange={handleDateChange} />
          </div>
        </div>

        {showModal && (
          <BankaForm
            show={showModal}
            title="Banka Hesabı Oluşturma Formu"
            onClose={() => setShowModal(false)}
          />
        )}

        {showModal2 && (
          <TransferBox
            show={showModal2}
            onClose={() => setShowModal2(false)}
          />
        )}

      </div>
      <div className='border w-full'>
        <HesapDurumNavBar />
      </div>

      <BankTable
        filters={filterConfig}
        autoUpdate={filterConfig.autoUpdate}
        bankAccounts={filteredAccounts}
      />
    </div>
  );
};

export default HesapContent;
