import { useState, useEffect } from 'react';
import '../index.css';
import axios from 'axios';
import { AiFillCheckSquare } from "react-icons/ai";
import { GiQuickSlash } from "react-icons/gi";

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;

function ParavanCekim() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [, setAccounts] = useState([]);
  const [withdrawal, setWithdrawal] = useState([]);
  const [formData, setFormData] = useState({
    refId: '',
    fullName: '',
    balance: '',
    username: 'bahsegel.user',
    accountName: '',
    accountNumber: '',
    targetAccountNumber: '',
    method: 'Iframe',
    note: ''
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/bank/accounts`);
      setAccounts(response.data.data);
      setError(null);
    } catch (error) {
      console.error("Error fetching bank accounts:", error);
      setError("Banka hesapları yüklenemedi. Lütfen tekrar deneyin.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setError(validationErrors.join(', '));
      return;
    }

    setError('');
    setLoading(true);

    const params = new URLSearchParams();
    Object.entries({
      refId: formData.refId.trim(),
      fullName: formData.fullName.trim(),
      balance: parseFloat(formData.balance),
      username: formData.username.trim(),
      accountName: formData.accountName.trim(),
      accountNumber: formData.accountNumber.trim(),
      targetAccountNumber: formData.targetAccountNumber.trim(),
      method: formData.method.trim(),
      note: formData.note.trim() || ''
    }).forEach(([key, value]) => {
      params.append(key, value);
    });

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${BASE_URL}/withdrawal/?${params.toString()}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        }
      });

      setWithdrawal([...withdrawal, response.data]);
      setLoading(true); // Onay kutusunu göstermek için true yap
      setTimeout(() => setLoading(false), 100000); // 5 saniye sonra loading'i false yap
    } catch (error) {
      let errorMessage = 'Çekim oluşturulurken bir hata oluştu.';
      if (error.response?.data?.detail) {
        if (Array.isArray(error.response.data.detail)) {
          const fieldErrors = error.response.data.detail.map(err => `${err.loc[1]}: ${err.msg}`).join(', ');
          errorMessage += ` Hatalı alanlar: ${fieldErrors}`;
        } else {
          errorMessage += ` Detay: ${error.response.data.detail}`;
        }
      }
      setError(errorMessage);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setFormData(prev => ({
      ...prev,
      [name]: name === 'balance' 
        ? value.replace(/[^\d]/g, '').replace(/^0+/, '') // Sadece sayıları al, başındaki 0'ları temizle
        : value
    }));
  };


  const validateForm = () => {
    const errors = [];
    if (!formData.fullName.trim()) errors.push("Ad Soyad alanı zorunludur");
    if (!formData.balance || isNaN(formData.balance) || parseFloat(formData.balance) <= 0)
      errors.push("Geçerli bir miktar giriniz");
    if (!formData.targetAccountNumber || formData.targetAccountNumber.length < 24)
      errors.push("Geçerli bir IBAN giriniz");
    return errors;
  };

  return (
    <div style={{ boxShadow: '4px 5px 35px white'}} className="flex login-form items-center p-10 mb-12 rounded-md bg-black bg-opacity-65 justify-center">
      <div className="flex flex-col items-center">
      <div className="flex flex-row items-center pl-5 w-full p-5 my-2 justify-center gap-0.5">
              <GiQuickSlash size={40} className="text-emerald-600" />
                <h1 className="text-5xl font-light text-white">
                  <span className="text-emerald-500 font-semibold">Dem</span>
                  <span>Fix</span>
                </h1>
            </div>

        {loading ? (
          <div className="p-5 h-72 w-64 flex flex-col items-center justify-center rounded-lg relative mx-2">
            <AiFillCheckSquare className="text-emerald-600 animate-pulse mb-4 duration-500 delay-200" size={45} />
            <p className="text-base font-mono text-white text-center tracking-wider font-bold animate-pulse">
              Çekim talebiniz başarıyla gönderilmiştir.
            </p>
            <h2 className="absolute bottom-2 text-xs text-center px-4 font-extralight text-white">
              Çekiminiz 15 dakika içerisinde onaylanacaktır, dilerseniz sayfayı kapatabilirsiniz.
            </h2>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex flex-col gap-6 rounded-lg p-5 h-72 w-64 items-center mx-2 justify-center">
              <h1 className="text-lg text-white font-thin animate-pulse">Hoşgeldiniz!</h1>

              <input
                type="text"
                name="fullName"
                className="p-2.5 w-56 text-center text-xs rounded-md"
                placeholder="Adınız Soyadınız"
                value={formData.fullName}
                onChange={handleChange}
                required
              />

              <input
                type="text"
                name="balance"
                className="p-2.5 w-56 text-center text-xs rounded-md"
                placeholder="Çekim Tutarı"
                value={formData.balance}
                onChange={handleChange}
                required
              />

              <input
                type="text"
                name="targetAccountNumber"
                className="p-2.5 text-xs w-56 text-center rounded-md"
                placeholder="IBAN numaranızı giriniz."
                value={formData.targetAccountNumber}
                onChange={handleChange}
                maxLength={24}
                required
              />

              <button
                type="submit"
                className="rounded-xl p-2 py-2.5 text-xs bg-emerald-600 text-white mt-4 w-32"
              >
                Çekim Talebi Ver
              </button>
            </div>
          </form>
        )}

        {error && (
          <div className="text-red-500 text-xs mt-4">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}

export default ParavanCekim;
