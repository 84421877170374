import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import RefsTable from '../components/RefsTable'

function refs() {
  return (
    <div>
      <>
        <Header />
        <NavBar />
        <RefsTable />
      </>
    </div>
  )
}

export default refs
