import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Environment'a göre ayarlayabilirsiniz
const BASE_URL = process.env.REACT_APP_API_URL;

const Logs = ({ show, onClose, title, accountId }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [logs, setLogs] = useState([]);
    const [, setUserMap] = useState({});

    useEffect(() => {
        if (show && accountId) {
            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, accountId]);

    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.get(`${BASE_URL}/users`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            // Create a map of user IDs to usernames
            const users = response.data.data.reduce((acc, user) => {
                acc[user.id] = user.username;
                return acc;
            }, {});

            setUserMap(users);
            return users;
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error;
        }
    };

    const fetchLogs = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.get(`${BASE_URL}/bank/logs/${accountId}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            return response.data.data;
        } catch (error) {
            console.error("Error fetching logs:", error);
            throw error;
        }
    };

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        
        try {
            // Fetch both logs and users in parallel
            const [logs, users] = await Promise.all([
                fetchLogs(),
                fetchUsers()
            ]);

            // Combine logs with username information
            const enrichedLogs = logs.map(log => ({
                ...log,
                username: users[log.userId] || 'Unknown User'
            }));

            setLogs(enrichedLogs);
        } catch (error) {
            setError(error.response?.data?.detail || 'Failed to fetch data');
        } finally {
            setLoading(false);
        }
    };

    const renderTransactionType = (type) => {
        const styles = {
            'Ekle': 'bg-green-400 text-slate-100',
            'Çıkar': 'bg-red-400 text-slate-100',
            'Ayar': 'bg-blue-400 text-slate-100'
        };

        return (
            <span className={`px-2 py-1 text-xs font-semibold rounded-full ${styles[type] || 'bg-gray-400 text-slate-100'}`}>
                {type}
            </span>
        );
    };

    const renderLogDetails = (log) => {
        if (log.transactionType === 'Ayar') {
            return (
                <>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-gray-900">
                        <span> - </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-900">
                        {log.old_value}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-900">
                        {log.new_value}
                    </td>
                </>
            );
        }

        return (
            <>
                <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-900">
                    {Number(log.amount).toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'TRY'
                    })}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-900">
                    {Number(log.previousBalance).toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'TRY'
                    })}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-900">
                    {Number(log.newBalance).toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'TRY'
                    })}
                </td>
            </>
        );
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4" style={{ zIndex: '9999' }}>
            <div className="bg-white rounded-lg w-full max-w-6xl max-h-[80vh] flex flex-col">
                {/* Header */}
                <div className="bg-emerald-600 p-4 rounded-t-lg">
                    <h2 className="text-white font-bold text-xl text-center">{title}</h2>
                </div>

                {/* Content */}
                <div className="flex-1 overflow-auto p-4">
                    {loading ? (
                        <div className="flex items-center justify-center py-8">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-500"></div>
                        </div>
                    ) : error ? (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                            <strong className="font-bold">Error! </strong>
                            <span className="block sm:inline">{error}</span>
                            <button 
                                onClick={fetchData}
                                className="text-blue-500 underline ml-4"
                            >
                                Retry
                            </button>
                        </div>
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y border-2 divide-gray-200">
                                <thead className="bg-gray-500">
                                    <tr>
                                        <th className="px-6 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                            Tarih
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                            Kullanıcı
                                        </th>
                                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-100 uppercase tracking-wider">
                                            İşlem Tipi
                                        </th>
                                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-100 uppercase tracking-wider">
                                            Miktar/Eski Değer
                                        </th>
                                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-100 uppercase tracking-wider">
                                            Önceki Bakiye/Değer
                                        </th>
                                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-100 uppercase tracking-wider">
                                            Yeni Bakiye/Değer
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-100 uppercase tracking-wider">
                                            Açıklama
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {logs.map((log) => (
                                        <tr key={log.id} className={` ${(log.transactionType === 'Ayar' ? 'bg-blue-200' : '')} ${(log.transactionType === 'Ekle' ? 'bg-emerald-100' : '')} ${(log.transactionType === 'Çıkar' ? 'bg-red-200' : '')}  hover:bg-opacity-85`}>
                                            <td className="px-6 py-4 `hitespace-nowrap text-xs text-gray-900">
                                                {new Date(log.created_at).toLocaleString()}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                                                <span className='p-1 bg-red-400 text-xs text-white rounded'>{log.username}</span>
                                            </td>
                                            <td className="px-6 py-4 text-center text-xs whitespace-nowrap">
                                                {renderTransactionType(log.transactionType)}
                                            </td>
                                            {renderLogDetails(log)}
                                            <td className="px-6 py-4 text-xs text-start text-gray-900">
                                                {log.explanation}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div className="border-t p-4 flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-red-500 text-white px-4 text-xs py-2 rounded-md hover:bg-red-600 transition-colors"
                    >
                        Kapat
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Logs;