import React from 'react'
import Header from '../components/Header.jsx'
import NavBar from '../components/NavBar.jsx'
import HesapContent from '../components/HesapContent.jsx'


function HesapYonetimi() {

  return (
    <>
    <div>
    <Header />
    <NavBar />
    <HesapContent />
    </div>
  </>
  )
}

export default HesapYonetimi


