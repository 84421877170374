import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import axios from 'axios'
import { useState, useEffect } from 'react';

const BASE_URL = process.env.REACT_APP_API_URL;


function HesapEklemeleriPage() {


  const [hesapEklemeleri, setHesapEklemeleri] = useState([]);
  const [userEklemeleri, setUserEklemeleri] = useState([]);
  const [hesapYatırımları, setHesapYatırımları] = useState([]);
  const [loading, setLoading] = useState(false);


  const fetchHesapEklemeleri = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/bank/accounts`);
      setHesapEklemeleri(response.data.count);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const fetchInvestments = async () => {
    
    const token = localStorage.getItem('token');
    try {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/investment/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        setHesapYatırımları(response.data.count);

    } catch (error) {
        console.error("Error fetching investments:", error);
    } finally {
        setLoading(false);
    }
};


  const fetchUserEklemeleri = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/users`);
      setUserEklemeleri(response.data.count);

    } catch (error) {
      console.error(error);
    }

    finally {
      setLoading(false);
    }

  }


  useEffect(() => {
    fetchHesapEklemeleri();
    fetchUserEklemeleri();
    fetchInvestments();

  }, []);


  return (
    <div>
      <Header />
      <NavBar />
      <div className='top-0 bottom-0 h-screen right-0 left-0'>
      <div>
        <h1 className='font-serif underline underline-offset-4 text-2xl text-black my-2 pl-4 pt-2'>GENEL HESAP BİLGİSİ</h1>
        <div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
            <h1 className='border-2 p-4 font-sans bg-gray-200 text-xs hover:bg-gray-300'>Mevcut Banka Hesap Sayısı : {hesapEklemeleri}</h1>
            </div>
          )}
        </div>

          <div>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
              <h1 className='border-2 p-4 font-sans bg-gray-200 text-xs hover:bg-gray-300'>Mevcut Admin Hesap Sayısı : {userEklemeleri}</h1>
              </div>
            )}
          </div>

          <div>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
              <h1 className='border-2 p-4 font-sans bg-gray-200 text-xs hover:bg-gray-300'>Mevcut Yatırım Sayısı : {hesapYatırımları}</h1>
              </div>
            )}
          </div>

      </div>
    </div>
    </div>

  )

}

export default HesapEklemeleriPage
