import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import HesapIstatistik from '../components/HesapIstatistik'

function HesapİstatistikPage() {
  return (
    <div>
      <Header />
      <NavBar />
      <HesapIstatistik />
    </div>
  )
}

export default HesapİstatistikPage