import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import KasaTakviyeLogs from '../components/KasaTakviyeLog'

function KasaTakviyeleriPage() {
  return (
    <div>
      <Header/>
      <NavBar/>
      <KasaTakviyeLogs/>
    </div>
  )
}

export default KasaTakviyeleriPage
