import React from 'react'
import Header from '../components/Header'
import NavBar from '../components/NavBar'
import Siteler from '../components/Siteler'


function SitelerPage() {
  return (
    <>
        <Header/>
        <NavBar />
        <Siteler />

    </>
  )
}

export default SitelerPage