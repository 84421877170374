import React, { useState, useEffect } from 'react';
import axios from 'axios';


const BASE_URL = process.env.REACT_APP_API_URL;

const BankForm = ({ show, onClose, title }) => {
    const [accounts, setAccounts] = useState([]);
    const [formData, setFormData] = useState({
        bankName: '',
        accountName: '',
        accountNumber: '',
        accountExplanation: '',
        isActive: false,
        isBlocked: false,
        isInvest: false,
        isPull: false,
        minInvest: '',
        maxInvest: '',
        minPull: '',
        maxPull: '',
        maxTransfer: '',
        maxAmount: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const getAllByBanks = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/bank/accounts`);
                const sortedAccounts = response.data.data.sort((a, b) => 
                    new Date(b.created_at) - new Date(a.created_at)
                );
                setAccounts(sortedAccounts);
            } catch (error) {
                console.error("Error fetching data: " + error);
            }
        };
        getAllByBanks();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const token = localStorage.getItem('token');

        const currentDate = new Date().toLocaleString("tr-TR", {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });

        const newBank = {
            ...formData,
            accountBalance: 0,
            userID: { token },
            created_at: { currentDate }
        };

        try {
            const response = await axios.post(`${BASE_URL}/bank/`, newBank, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            setAccounts([...accounts, response.data]);
            setShowModal(true);
            setFormData({
                bankName: '',
                accountName: '',
                accountNumber: '',
                accountExplanation: '',
                isActive: false,
                isBlocked: false,
                isInvest: false,
                isPull: false,
                minInvest: '',
                maxInvest: '',
                minPull: '',
                maxPull: '',
                maxTransfer: '',
                maxAmount: '',
                created_at: {currentDate},
            });
        } catch (error) {
            console.error("Error saving data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        window.location.reload();
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex z-50 items-center justify-center p-4">
            <div className="bg-white rounded-lg w-full h-auto max-w-2xl">
                <div className="bg-emerald-600 p-4 rounded-t-lg">
                    <h1 className="text-white font-bold text-xl text-center">{title}</h1>
                </div>

                <form onSubmit={handleSubmit} className="p-3 space-y-6">
                    <div className="flex flex-row justify-evenly gap-2 items-start">
                        {/* Bank Information */}
                        <div className="space-y-4 flex flex-col w-60">
                        <h2 className='text-xl text-start font-bold underline underline-offset-4 my-4 mb-8'>Hesap Bilgileri</h2>
                            <div>
                                <label className="block text-sm font-bold mb-2">Banka Adı</label>
                                <select
                                    name="bankName"
                                    value={formData.bankName}
                                    onChange={handleChange}
                                    className="w-full p-1 text-xs border rounded-md"
                                    required
                                >
                                    <option value="">Seçiniz</option>
                                    <option value="Halkbank">Halkbank</option>
                                    <option value="Ziraat Bankası">Ziraat Bankası</option>
                                    <option value="Garanti Bankası">Garanti Bankası</option>
                                    <option value="Akbank">Akbank</option>
                                    <option value="Yapı Kredi Bankası">Yapı Kredi Bankası</option>
                                    <option value="İng Bank">İng Bank</option>
                                    <option value="Denizbank">Denizbank</option>
                                    <option value="QNB Finansbank">QNB Finansbank</option>
                                    <option value="Türkiye İş Bankası">Türkiye İş Bankası</option>
                                    <option value="Şekerbank">ŞekerBank</option>
                                    <option value="Fibabanka">FibaBank</option>
                                    <option value="AktifBank">AktifBank</option>
                                    <option value="BurganBank">BurganBank</option>
                                    <option value="TEB">Türk Ekonomi Bankası</option>
                                    <option value="TürkiyeFinans">TürkiyeFinans</option>
                                    <option value="Kuveyttürk">Kuveyttürk</option>
                                    <option value="OdeaBank">OdeaBank</option>
                                    <option value="AlbarakaBank">AlbarakaBank</option>
                                    <option value="AnadoluBank">AnadoluBank</option>
                    
                                </select>
                            </div>

                            <div>
                                <label className="block text-xs font-bold mb-2">Hesap Sahibi</label>
                                <input
                                    type="text"
                                    name="accountName"
                                    value={formData.accountName}
                                    onChange={handleChange}
                                    className="w-full p-1 text-xs border rounded-md"
                                    placeholder="Adı Soyadı"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-xs font-bold mb-2">IBAN Numarası</label>
                                <input
                                    type="text"
                                    name="accountNumber"
                                    value={formData.accountNumber}
                                    onChange={handleChange}
                                    maxLength={24}
                                    className="w-full p-1 text-xs border rounded-md"
                                    placeholder="Başında TR olmadan giriş yapınız."
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-xs font-bold mb-2">Açıklama</label>
                                <input
                                    type="text"
                                    name="accountExplanation"
                                    value={formData.accountExplanation}
                                    onChange={handleChange}
                                    className="w-full p-1 text-xs border rounded-md"
                                    placeholder="aciklama"
                                />
                            </div>

                            <div className="flex gap-4">
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="isActive"
                                        checked={formData.isActive}
                                        onChange={handleChange}
                                        className="w-3 h-3"
                                    />
                                    <span className="text-xs font-bold">Hesap Aktif</span>
                                </label>

                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="isBlocked"
                                        checked={formData.isBlocked}
                                        onChange={handleChange}
                                        className="w-3 h-3"
                                    />
                                    <span className="text-xs font-bold">Hesap Bloke</span>
                                </label>
                            </div>
                        </div>

                        {/* Account Settings */}
                        <div className="space-y-4 flex flex-col w-60">
                        <h2 className='text-xl text-start my-4 mb-8 font-bold underline underline-offset-4'>Hesap Ayarları</h2>
                            <div className="flex gap-4">
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="isInvest"
                                        checked={formData.isInvest}
                                        onChange={handleChange}
                                        className="w-3 h-3"
                                    />
                                    <span className="text-xs font-bold">Yatırım Aktif</span>
                                </label>

                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="isPull"
                                        checked={formData.isPull}
                                        onChange={handleChange}
                                        className="w-3 h-3"
                                    />
                                    <span className="text-xs font-bold">Çekim Aktif</span>
                                </label>
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-xs font-bold mb-2">Max Yatırım</label>
                                    <input
                                        type="number"
                                        name="maxInvest"
                                        value={formData.maxInvest}
                                        onChange={handleChange}
                                        className="w-full p-1 text-xs border rounded-md"
                                        placeholder="50,000₺"
                                    />
                                </div>

                                <div>
                                    <label className="block text-xs font-bold mb-2">Min Yatırım</label>
                                    <input
                                        type="number"
                                        name="minInvest"
                                        value={formData.minInvest}
                                        onChange={handleChange}
                                        className="w-full p-1 text-xs border rounded-md"
                                        placeholder="500₺"
                                    />
                                </div>

                                <div>
                                    <label className="block text-xs font-bold mb-2">Max Çekim</label>
                                    <input
                                        type="number"
                                        name="maxPull"
                                        value={formData.maxPull}
                                        onChange={handleChange}
                                        className="w-full p-1 text-xs border rounded-md"
                                        placeholder="50,000₺"
                                    />
                                </div>

                                <div>
                                    <label className="block text-xs font-bold mb-2">Min Çekim</label>
                                    <input
                                        type="number"
                                        name="minPull"
                                        value={formData.minPull}
                                        onChange={handleChange}
                                        className="w-full p-1 text-xs border rounded-md"
                                        placeholder="5000₺"
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-xs font-bold mb-2">Max Aylık İşlem</label>
                                    <input
                                        type="number"
                                        name="maxTransfer"
                                        value={formData.maxTransfer}
                                        onChange={handleChange}
                                        className="w-full p-1 text-xs border rounded-md"
                                        placeholder="50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-xs font-bold mb-2">Max Bakiye</label>
                                    <input
                                        type="number"
                                        name="maxAmount"
                                        value={formData.maxAmount}
                                        onChange={handleChange}
                                        className="w-full p-1 text-xs border rounded-md"
                                        placeholder="150,000₺"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end gap-4 pt-4">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="bg-emerald-500 text-white px-4 py-2 text-xs rounded-md hover:bg-emerald-600 disabled:opacity-50"
                        >
                            {isLoading ? 'Hesap Ekleniyor...' : 'Hesabı Ekle'}
                        </button>

                        <button
                            type="button"
                            onClick={onClose}
                            disabled={isLoading}
                            className="bg-red-500 text-white px-4 text-xs py-2 rounded-md hover:bg-red-600 disabled:opacity-50"
                        >
                            Kapat
                        </button>
                    </div>

                    <div className="text-center text-sm text-gray-600 bg-gray-100 p-4 rounded-md mt-6">
                        <p className="font-semibold">Lütfen tüm alanları eksiksiz doldurunuz.</p>
                        <p>Anlayışınız için teşekkür ederiz.</p>
                        <p>Dem<span className="font-bold">Fix</span></p>
                    </div>
                </form>
            </div>

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white flex flex-col items-center justify-center p-6 rounded-lg">
                        <h2 className="text-xl font-bold mb-4">Banka Hesabı Başarıyla Eklendi.</h2>
                        <button
                            onClick={handleCloseModal}
                            className="bg-red-500 text-white px-2 w-14 text-xs text-center py-2 rounded-md hover:bg-red-600"
                        >
                            Kapat
                        </button>
                    </div>
                </div>
            )}

            </div>
    );
};

export default BankForm;