import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ component: Component, allowedRoles, publicRoute = false }) => {
  
  const token = localStorage.getItem('token');

  // Eğer publicRoute true ise, token gerektirme ve Component'i direkt render et
  if (publicRoute) {
    return <Component />;
  }

  // Eğer token yoksa, login sayfasına yönlendir
  if (!token) {
    return <Navigate to="/login" />;
  }

  try {
    const decodedToken = jwtDecode(token);
    const userRole = decodedToken.role;
    const isAdmin = decodedToken.is_admin;

    // Eğer kullanıcı adminse, tüm sayfalara erişebilir
    if (isAdmin) {
      return <Component />;
    }

    // Kullanıcının rolü izin verilen roller ile uyumlu mu?
    const hasPermission = allowedRoles.includes(userRole);
    
    if (!hasPermission) {
      alert('Erişim izniniz bulunmamaktadır.');
      return <Navigate to="/" />;
    }

    // Token ve rol doğruysa Component render edilir
    return <Component />;
  } catch (error) {
    console.error('Token decode error:', error);
    localStorage.removeItem('token');
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
