import React, { useState } from "react";
import ParaOlanHesapForm from '../components/ParaOlanHesapForm';
import ParaOlmayanHesapForm from '../components/ParaOlmayanHesapForm';
import AktifHesapForm from "../components/AktifHesapForm";
import PasifHesapForm from "../components/PasifHesapForm";
import YatırımYapılabilirHesap from '../components/YatırımYapılabilirHesapForm';
import CekimYapılabilirHesap from '../components/CekimYapılabilirHesapForm';

const HesapDurumNavBar = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      <div className="flex items-center flex-1 pl-4 mb-3 justify-start flex-wrap">
        {/* Dropdown Selection */}
        <div className="flex items-start flex-col pl-2 justify-start gap-4 text-xs">
          <label className="flex items-center justify-center gap-1">
            <select
              value={selectedOption}
              onChange={handleSelectionChange}
              className="border border-gray-300 p-1 rounded-md"
            >
              <option value="">Tüm Hesaplar </option>
              <option value="aktifHesaplar">Aktif Hesaplar</option>
              <option value="pasifHesaplar">Pasif Hesaplar</option>
              <option value="paraOlanHesaplar">Para Olan Hesaplar</option>
              <option value="paraOlmayanHesaplar">Para Olmayan Hesaplar</option>
              <option value="yatırımAktifHesaplar">Yatırım Yapılabilir Hesaplar</option>
              <option value="cekimAktifHesaplar">Çekim Yapılabilir Hesaplar</option>
              <option value="kullanilanHesaplar">Kullanılan Hesaplar</option>
              <option value="kullanilmayanHesaplar">Kullanılmayan Hesaplar</option>
            </select>
          </label>
        </div>
      </div>

      {/* Conditional Rendering Based on the Selected Option */}
      {selectedOption === "aktifHesaplar" && <AktifHesapForm />}
      {selectedOption === "pasifHesaplar" && <PasifHesapForm />}
      {selectedOption === "paraOlanHesaplar" && <ParaOlanHesapForm />}
      {selectedOption === "paraOlmayanHesaplar" && <ParaOlmayanHesapForm />}
      {selectedOption === "yatırımAktifHesaplar" && <YatırımYapılabilirHesap />}
      {selectedOption === "cekimAktifHesaplar" && <CekimYapılabilirHesap />}
      {selectedOption === "kullanilanHesaplar" && (
        <table>
          <thead>
            <tr>
              <th>Kullanılan Hesaplar Tablosu</th>
            </tr>
          </thead>
          <tbody>
            {/* Table rows can go here */}
          </tbody>
        </table>
      )}
      {selectedOption === "kullanilmayanHesaplar" && (
        <table>
          <thead>
            <tr>
              <th>Kullanılmayan Hesaplar Tablosu</th>
            </tr>
          </thead>
          <tbody>
            {/* Table rows can go here */}
          </tbody>
        </table>
      )}
    </>
  );
};

export default HesapDurumNavBar;
