import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import UserMenu from '../components/UserMenu';
import { GiQuickSlash } from "react-icons/gi";


const BASE_URL = process.env.REACT_APP_API_URL;

const Header = () => {
  const [userName, setUserName] = useState('');
  const [refsData, setRefsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/users/me`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        setUserName(response.data.username);
        setError(null);
      } catch (error) {
        console.error("Veri alınırken bir hata oluştu: ", error);
        setError("Veri alınamadı. Lütfen daha sonra tekrar deneyin.");
        
        if (error.response?.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    };


    fetchData();
  }, [navigate]);

  return (
    <div>
      <header className="relative py-2 md:py-3 bg-gray-800 w-full">
        <div className="flex items-center justify-between px-4">
          <div className="inline-block lg:hidden">
            <Sidebar />
          </div>
          <div className="items-center hidden lg:flex justify-center">
            <a href="/">
              <div className='flex flex-row items-center justify-center gap-0.5'>
                <GiQuickSlash size={25} className='text-emerald-600'/>
                <h1 className="text-3xl font-light text-white">
                  <span className="text-emerald-500 font-semibold">Dem</span><span>Fix</span>
                </h1>
              </div> 
            </a>
          </div>
          <div className="flex items-center cursor-pointer justify-center gap-4">
          
            <div className="flex items-center cursor-pointer justify-center">
              <input placeholder="Ara..." type="search" className="w-24 lg:w-32 p-0.5 text-start md:p-1 border-2 border-gray-800 text-xs pl-1 rounded-md" />
            </div>
            <div className="flex items-center text-white cursor-pointer gap-2 justify-center">
              <UserMenu userName={userName} />
            </div>
          </div>
        </div>
      </header>
      
      {error && <p>{error}</p>}
    </div>
  );
}

export default Header;
