import React from 'react'
import Header from '../components/Header.jsx'
import NavBar from '../components/NavBar.jsx'
import YatırımContent from '../components/YatırımContent.jsx'


function yatirimtalepleri() {
  return (
      <>
        <Header />
        <NavBar />
        <YatırımContent/>
      </>   
  )
}

export default yatirimtalepleri
