import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const RefsLogs = ({ show, onClose, refId, title }) => {
    const [balanceLogs, setBalanceLogs] = useState(null);
    const [transferLogs, setTransferLogs] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (show && refId) {
            fetchLogs();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, refId]);

    const fetchLogs = async () => {
        const token = localStorage.getItem('token');
        try {
            setLoading(true);
            // Fetch both balance operations and transfers
            const [balanceResponse, transferResponse] = await Promise.all([
                axios.get(`${BASE_URL}/refs/${refId}/balance_operations`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                }),
                axios.get(`${BASE_URL}/refs/transfers/${refId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                })
            ]);

            setBalanceLogs(balanceResponse.data);
            setTransferLogs(transferResponse.data);
            setError(null);
        } catch (error) {
            console.error('Error fetching logs:', error);
            setError('Failed to fetch logs');
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg w-full max-w-4xl max-h-[80vh] overflow-hidden">
                <div className="p-4 bg-gray-800 text-white flex justify-between items-center">
                    <h2 className="text-lg font-semibold text-white">{title}</h2>
                    <button
                        onClick={onClose}
                        className="text-white hover:text-gray-300"
                    >
                        ✕
                    </button>
                </div>

                <div className="p-4 overflow-y-auto max-h-[calc(80vh-4rem)]">
                    {loading ? (
                        <div className="flex justify-center items-center py-8">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-emerald-500"></div>
                        </div>
                    ) : error ? (
                        <div className="text-red-500 text-center py-4">{error}</div>
                    ) : (
                        <div>
                            {/* Balance Information */}
                            <div className="mb-4 p-4 bg-gray-100 rounded-lg">
                                <p className="text-sm font-semibold text-emerald-600">
                                    Mevcut Bakiye: {Number(balanceLogs?.current_balance || 0).toLocaleString("tr-TR", { minimumFractionDigits: 2 })} ₺
                                </p>
                            </div>

                            {/* Transfers OUT */}
                            <div className="mb-6">
                                <h3 className="text-lg font-semibold mb-2 text-gray-700">Giden Transferler</h3>
                                <div className="bg-white shadow rounded-lg overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Tarih</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Miktar</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Not</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {transferLogs?.transfers_out?.map((log) => (
                                                <tr key={log.id} className="hover:bg-gray-50">
                                                    <td className="px-6 py-4 text-xs text-gray-900">
                                                        {formatDate(log.date)}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-orange-600">
                                                        -{Number(log.amount).toLocaleString("tr-TR", { minimumFractionDigits: 2 })} ₺
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-gray-900">
                                                        {log.note}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Transfers IN */}
                            <div className="mb-6">
                                <h3 className="text-lg font-semibold mb-2 text-gray-700">Gelen Transferler</h3>
                                <div className="bg-white shadow rounded-lg overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Tarih</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Miktar</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Not</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {transferLogs?.transfers_in?.map((log) => (
                                                <tr key={log.id} className="hover:bg-gray-50">
                                                    <td className="px-6 py-4 text-xs text-gray-900">
                                                        {formatDate(log.date)}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-blue-600">
                                                        +{Number(log.amount).toLocaleString("tr-TR", { minimumFractionDigits: 2 })} ₺
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-gray-900">
                                                        {log.note}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Balance Additions */}
                            <div className="mb-6">
                                <h3 className="text-lg font-semibold mb-2 text-gray-700">Eklenen İşlemler</h3>
                                <div className="bg-white shadow rounded-lg overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Tarih</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Miktar</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Not</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {balanceLogs?.balance_additions?.map((log) => (
                                                <tr key={log.id} className="hover:bg-gray-50">
                                                    <td className="px-6 py-4 text-xs text-gray-900">
                                                        {formatDate(log.date)}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-green-600">
                                                        +{Number(log.amount).toLocaleString("tr-TR", { minimumFractionDigits: 2 })} ₺
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-gray-900">
                                                        {log.note}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Balance Subtractions */}
                            <div>
                                <h3 className="text-lg font-semibold mb-2 text-gray-700">Çıkarılan İşlemler</h3>
                                <div className="bg-white shadow rounded-lg overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Tarih</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Miktar</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Not</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {balanceLogs?.balance_subtractions?.map((log) => (
                                                <tr key={log.id} className="hover:bg-gray-50">
                                                    <td className="px-6 py-4 text-xs text-gray-900">
                                                        {formatDate(log.date)}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-red-600">
                                                        -{Number(log.amount).toLocaleString("tr-TR", { minimumFractionDigits: 2 })} ₺
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-gray-900">
                                                        {log.note}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RefsLogs;