import React from 'react'
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import HesapYatırımları from '../components/HesapYatırımları';


function HesapYatırımlarıPage() {
  return (
    <div>
      <Header />
      <NavBar />
      <HesapYatırımları />
    </div>
  )
}

export default HesapYatırımlarıPage
