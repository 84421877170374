import React, { useState, useEffect } from 'react';
import axios from 'axios';


const BASE_URL = process.env.REACT_APP_API_URL;

const BankaUpdateForm = ({ show, onClose, title, accountId }) => {
    const [formData, setFormData] = useState({
        bankName: '',
        accountName: '',
        accountNumber: '',
        accountExplanation: '',
        isActive: false,
        isBlocked: false,
        isInvest: false,
        isPull: false,
        minInvest: '',
        maxInvest: '',
        minPull: '',
        maxPull: '',
        maxTransfer: '',
        maxAmount: ''
    });

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (accountId) {
            fetchAccountDetails();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId]);

    const fetchAccountDetails = async () => {
        setIsLoading(true);
        setError(null);
        
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.get(`${BASE_URL}/bank/accounts`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                }
            });

            const account = response.data.data.find(acc => acc.accountId === accountId);
            
            if (!account) {
                throw new Error('Account not found');
            }

            setFormData({
                bankName: account.bankName || '',
                accountName: account.accountName || '',
                accountNumber: account.accountNumber || '',
                accountExplanation: account.accountExplanation || '',
                isActive: Boolean(account.isActive),
                isBlocked: Boolean(account.isBlocked),
                isInvest: Boolean(account.isInvest),
                isPull: Boolean(account.isPull),
                minInvest: account.minInvest ? Number(account.minInvest) : '',
                maxInvest: account.maxInvest ? Number(account.maxInvest) : '',
                minPull: account.minPull ? Number(account.minPull) : '',
                maxPull: account.maxPull ? Number(account.maxPull) : '',
                maxTransfer: account.maxTransfer ? Number(account.maxTransfer) : '',
                maxAmount: account.maxAmount ? Number(account.maxAmount) : ''
            });
        } catch (error) {
            console.error('Error fetching account details:', error);
            setError(error.message || 'Failed to fetch account details');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const updateData = {
                bankName: formData.bankName || null,
                accountName: formData.accountName || null,
                accountNumber: formData.accountNumber ? String(formData.accountNumber) : null,
                accountExplanation: formData.accountExplanation || null,
                isActive: Boolean(formData.isActive),
                isBlocked: Boolean(formData.isBlocked),
                isInvest: Boolean(formData.isInvest),
                isPull: Boolean(formData.isPull),
                maxInvest: formData.maxInvest ? Number(formData.maxInvest) : null,
                minInvest: formData.minInvest ? Number(formData.minInvest) : null,
                maxPull: formData.maxPull ? Number(formData.maxPull) : null,
                minPull: formData.minPull ? Number(formData.minPull) : null,
                maxTransfer: formData.maxTransfer ? Number(formData.maxTransfer) : null,
                maxAmount: formData.maxAmount ? Number(formData.maxAmount) : null
            };

            const cleanedData = Object.fromEntries(
                Object.entries(updateData).filter(([_, value]) => value !== null)
            );

            await axios.patch(
                `${BASE_URL}/bank/${accountId}`,
                cleanedData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                    }
                }
            );

            setShowModal(true);
        } catch (error) {
            console.error('Error updating account:', error);
            setError(error.response?.data?.detail || 'Failed to update account');
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked :
                    type === 'number' ? (value === '' ? '' : Number(value)) :
                    value
        }));
    };

    const handleCloseModal = () => {
        setShowModal(false);
        onClose();
        window.location.reload();
    };

    if (!show) return null;

    if (isLoading) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg">
                    <p className="text-lg font-semibold">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4" style={{ zIndex: '9999' }}>
        <div className='flex items-center justify-center'>
            <div className="bg-white rounded-lg w-full max-w-2xl">
                <div className="bg-emerald-600 p-4 rounded-t-lg">
                    <h1 className="text-white font-bold text-xl text-center">{title}</h1>
                </div>

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 m-4 rounded relative">
                        <strong className="font-bold">Error! </strong>
                        <span className="block sm:inline">{error}</span>
                        <button 
                            onClick={fetchAccountDetails}
                            className="text-blue-500 underline ml-4"
                        >
                            Retry
                        </button>
                    </div>
                )}

                <form onSubmit={handleUpdate} className="p-6 space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Bank Information */}
                        <div className="space-y-4">
                        <h2 className='text-xl md:text-2xl font-bold underline underline-offset-4 my-4 mb-8'>Hesap Bilgileri</h2>
                            <div>
                                <label className="block text-sm font-bold mb-2">Banka Adı</label>
                                <select
                                    name="bankName"
                                    value={formData.bankName}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded-md"
                                >
                                    <option value="">Seçiniz</option>
                                    <option value="Halkbank">Halkbank</option>
                                    <option value="Ziraat Bankası">Ziraat Bankası</option>
                                    <option value="Garanti Bankası">Garanti Bankası</option>
                                    <option value="Akbank">Akbank</option>
                                    <option value="Yapı Kredi Bankası">Yapı Kredi Bankası</option>
                                    <option value="İng Bank">İng Bank</option>
                                    <option value="Denizbank">Denizbank</option>
                                    <option value="QNB Finansbank">QNB Finansbank</option>
                                    <option value="Türkiye İş Bankası">Türkiye İş Bankası</option>
                                    <option value="VakıfBank">VakıfBank</option>
                                </select>
                            </div>

                            <div>
                                <label className="block text-sm font-bold mb-2">Hesap Sahibi</label>
                                <input
                                    type="text"
                                    name="accountName"
                                    value={formData.accountName}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded-md"
                                    placeholder="Adı Soyadı"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-bold mb-2">IBAN</label>
                                <input
                                    type="text"
                                    name="accountNumber"
                                    value={formData.accountNumber}
                                    onChange={handleInputChange}
                                    maxLength={24}
                                    className="w-full p-2 border rounded-md"
                                    placeholder=""
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-bold mb-2">Açıklama</label>
                                <input
                                    type="text"
                                    name="accountExplanation"
                                    value={formData.accountExplanation}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded-md"
                                    placeholder=""
                                />
                            </div>
                            <div className="flex gap-4">
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="isActive"
                                        checked={formData.isActive}
                                        onChange={handleInputChange}
                                        className="w-4 h-4"
                                    />
                                    <span className="text-sm font-bold">Hesap Aktif</span>
                                </label>

                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="isBlocked"
                                        checked={formData.isBlocked}
                                        onChange={handleInputChange}
                                        className="w-4 h-4"
                                    />
                                    <span className="text-sm font-bold">Hesap Bloke</span>
                                </label>
                            </div>
                         
                        </div>
                        {/* Account Settings */}
                        <div className="space-y-4">
                        <h2 className='text-xl md:text-2xl font-bold underline underline-offset-4 my-4 mb-8'>Hesap Ayarları</h2>
                            <div className="flex gap-4">
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="isInvest"
                                        checked={formData.isInvest}
                                        onChange={handleInputChange}
                                        className="w-4 h-4"
                                    />
                                    <span className="text-sm font-bold">Yatırım Aktif</span>
                                </label>

                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        name="isPull"
                                        checked={formData.isPull}
                                        onChange={handleInputChange}
                                        className="w-4 h-4"
                                    />
                                    <span className="text-sm font-bold">Çekim Aktif</span>
                                </label>
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-bold mb-2">Max Yatırım</label>
                                    <input
                                        type="number"
                                        name="maxInvest"
                                        value={formData.maxInvest}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="50,000₺"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold mb-2">Min Yatırım</label>
                                    <input
                                        type="number"
                                        name="minInvest"
                                        value={formData.minInvest}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="500₺"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold mb-2">Max Çekim</label>
                                    <input
                                        type="number"
                                        name="maxPull"
                                        value={formData.maxPull}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="50,000₺"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold mb-2">Min Çekim</label>
                                    <input
                                        type="number"
                                        name="minPull"
                                        value={formData.minPull}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="5000₺"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold mb-2">Max Aylık İşlem</label>
                                    <input
                                        type="number"
                                        name="maxTransfer"
                                        value={formData.maxTransfer}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-bold mb-2">Max Bakiye</label>
                                    <input
                                        type="number"
                                        name="maxAmount"
                                        value={formData.maxAmount}
                                        onChange={handleInputChange}
                                        className="w-full p-2 border rounded-md"
                                        placeholder="150,000₺"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end gap-4 pt-4">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="bg-emerald-500 text-white px-4 text-xs py-2 rounded-md hover:bg-emerald-600 disabled:opacity-50"
                        >
                            {isLoading ? 'Güncelleniyor...' : 'Güncelle'}
                        </button>

                        <button
                            type="button"
                            onClick={onClose}
                            disabled={isLoading}
                            className="bg-red-500 text-white px-4 text-xs py-2 rounded-md hover:bg-red-600 disabled:opacity-50"
                        >
                            Kapat
                        </button>
                    </div>

                    <div className="text-center text-sm text-gray-600 bg-gray-100 p-4 rounded-md mt-6">
                        <p className="font-semibold">Lütfen tüm alanları eksiksiz doldurunuz.</p>
                        <p>Anlayışınız için teşekkür ederiz.</p>
                        <p>Dem<span className="font-bold">Fix</span></p>
                    </div>
                </form>
            </div>

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white flex flex-col items-center justify-center p-6 rounded-lg">
                        <h2 className="text-xl font-bold mb-4">Banka Başarıyla Güncellendi.</h2>
                        <button
                            onClick={handleCloseModal}
                            className="bg-red-500 text-white px-2 w-14 text-xs text-center py-2 rounded-md hover:bg-red-600"
                        >
                            Kapat
                        </button>
                    </div>
                </div>
            )}
            </div>
        </div>
    );
};

export default BankaUpdateForm;